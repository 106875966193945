import React, { useEffect, useState, memo } from 'react';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, NumbCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { Summary } from 'devextreme-react/cjs/data-grid';
import { GroupSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { fwUtil } from '../../../../script/util';

// 상태 셀 렌더링 함수
const chrgStateCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = fwUtil.conv.to.krChrgStat(value);
    const color = fwUtil.conv.to.chrgColors(value);

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    );
};

// 그룹 렌더링 함수
const stationGroupRender = (data) => {
    if (!data || data.length < 1) {
        return null;
    }

    const kwhColumn = data.summaryItems[0];
    const cntColumn = data.summaryItems[1];
    const payColumn = data.summaryItems[2];

    return (
        <div className='fw-custom-gruop-moni-dash'>
            <div className='fw-group-title'>
                {data.displayValue || '알수없음'}
            </div>
            <div className='fw-group-detail'>
                총 {kwhColumn.columnCaption} <b>{kwhColumn.value}</b> kWh |
            </div>
            <div className='fw-group-detail'>
                총 {cntColumn.columnCaption} <b>{cntColumn.value}</b> 회 | 
            </div>            
            <div className='fw-group-detail'>
                총 {payColumn.columnCaption} <b>{fwUtil.conv.to.krLocales(payColumn.value)}</b> 원
            </div>
        </div>
    );
};

// 메모이제이션된 컴포넌트
const MoniDashChrgGrid = memo((props) => {
    const {
        gridData, gridRef, daysGridHeight,
        mainTitle, mainRole, additonTitle
    } = props;
    
    // 그리드
    const mainKey = ["station_id", "chargePointId", "connectorId"];
    const [gridHeight, setGridHeight] = useState(0);    

    useEffect(() => {
        const pageContentElement = document.getElementById('fw-page-content');
        if (pageContentElement) {
            const pageContentHeight = pageContentElement.clientHeight;
            setGridHeight(pageContentHeight - daysGridHeight - 20);
        }
    }, [daysGridHeight]);    

    return (
        <ScrollGrid
            title={mainTitle}
            role={mainRole}
            additonTitle={additonTitle}
            height={gridHeight}
            mainKey={mainKey}
            gridRef={gridRef}
            gridDataSource={gridData}
            loadPanel={false}
            toolbar={{ titleOnly: true }}
            selection={'single'}
        >
            {CommCol({ dataField: "station_name", caption: "사업장", displayExpr: "station_name", groupIndex: 0, groupRender: stationGroupRender })}
            {CommCol({ dataField: "chargePointId", caption: "충전기명" })}
            {StatCol({ dataField: "state", caption: "상태", customCell: chrgStateCell })}         
            {DeciCol({ dataField: "usePower", caption: "충전량", suffix: "kWh" })}
            {NumbCol({ dataField: "usageCount", caption: "충전 횟수" })}
            {DeciCol({ dataField: "resultPrice", caption: "매출액", suffix: "원" })}
            <Summary>
                {GroupSumItem({ dataField: "usePower", suffix: "kWh" })}
                {GroupSumItem({ dataField: "usageCount", suffix: "회" })}
                {GroupSumItem({ dataField: "resultPrice", suffix: "원" })}
            </Summary>
        </ScrollGrid>
    );
});

export default MoniDashChrgGrid;