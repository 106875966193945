import { moni } from "./moni";
import { chrg } from "./chrg";
import { infr } from "./intr";
import { user } from "./user";
import { comm } from "./comm";
import { anal } from "./anal";
import routerInfo from "../../../utils/router-info";

// 모니터링
const MoniInstance = moni({ acce: routerInfo.manaRout, code: 'A' });
export const MoniDashStat = () => MoniInstance.dashStat;
export const MoniMapsStat = () => MoniInstance.mapsStat;
// 충전 관리
const ChrgInstance = chrg({ acce: routerInfo.manaRout, code: 'B' });
export const ChrgBusiMana = () => ChrgInstance.busiMana;
export const ChrgStatMana = () => ChrgInstance.statMana;
export const ChrgInfoMana = () => ChrgInstance.infoMana;
export const ChrgPricMana = () => ChrgInstance.pricMana;
// 게시판 관리
// 인프라 관리
const InfrInstance = infr({ acce: routerInfo.manaRout, code: 'D' });
export const InfrAserMana = () => InfrInstance.aserMana;
// 사용자 관리
const UserInstance = user({ acce: routerInfo.manaRout, code: 'E' });
export const UserAccoMana = () => UserInstance.accoMana;
export const UserCredMana = () => UserInstance.credMana;
export const UserRfidMana = () => UserInstance.rfidMana;
// 정산/지표 분석
const AnalInstance = anal({ acce: routerInfo.manaRout, code: 'F' });
export const AnalDailStat = () => AnalInstance.dailStat;
export const AnalChrgStat = () => AnalInstance.chrgStat;
export const AnalAserStat = () => AnalInstance.aserStat;
// 기준정보
const CommInstance = comm({ acce: routerInfo.manaRout, code: 'G' });
export const CommCodeMana = () => CommInstance.codeMana;