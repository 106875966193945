// 사이드바 네비게이션 트리에 사용되는 라우터 경로
function menuLoop(pUserMenu, pMenuCode) {
  let result = pUserMenu.map(menuItem => {
    let menuCode = menuItem.menu_code.trim();
    let menuHigh = menuItem.menu_high.trim();
    let menuName = menuItem.menu_name.trim();
    let menuPath = menuItem.menu_path.trim();
    let menuIcon = menuItem.menu_icon.trim();
    if (menuHigh === pMenuCode) {
      if (menuPath === "") {
        return ({
          text: menuName,
          icon: menuIcon,
          items: menuLoop(pUserMenu, menuCode),
        });
      } else {
        return ({
          text: menuName,
          path: menuPath,
        });
      }
    } else {
      return null;
    }
  }).filter(item => item !== null);
  return result;
};

export function CreateTreeNavigation(userMenu, menu) {
  let menuData = [];

  if (menu === 'cms') {
    const cmsRoute = userMenu
    .filter(menuItem => menuItem.menu_high.trim() === "")
    .map(menuItem => {
      let menuCode = menuItem.menu_code.trim();
      let menuName = menuItem.menu_name.trim();
      let menuIcon = menuItem.menu_icon.trim();

      return {
        text: menuName,
        icon: menuIcon,
        items: menuLoop(userMenu, menuCode),
      };
    });
    menuData.push(...cmsRoute);
  }
  
  return menuData;
};