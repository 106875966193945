import IntrLogo from '../focuswin/assets/comp-logo-intr.png';
import SuppLogo from '../focuswin/assets/comp-logo-supp.png';

const intrComp = {
    logo: IntrLogo,
    name: "미래씨엔엘(주)"
};

const suppComp = {
    logo: SuppLogo,
    name: "FOCUSWIN"
};

export { intrComp, suppComp };