import { Routes, Route, Navigate } from 'react-router-dom';
import { AccountPanel, LoginForm } from './focuswin/script/components';
import { intrComp, suppComp } from './utils/default-comp';

export default function AuthContent() {
  return (
    <Routes>
      <Route
        path='/login' 
        element={
          <AccountPanel title="로 그 인" detail="사용자 ID와 비밀번호를 입력하여 주세요." intrComp={intrComp} suppComp={suppComp} >
            <LoginForm />
          </AccountPanel>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
