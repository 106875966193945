// InfrAserMana - 인프라 관리 - A/S이력 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { InfrAserInsert } from './InfoMana-insert';
import { InfrAserUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

const aserStateCell = (cellInfo) => {
    const value = cellInfo.value;

    const colorMap = {
        '접수': '#5bc0de',
        '안내': '#f0ad4e',
        '예정': '#5bc0de',
        '진행중': '#0275d8',
        '완료': '#5cb85c'
    };

    const color = colorMap[value] || '#6c757d';

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>
                ● {value || "알수없음"}
            </span>
        </div>
    );
};
export default function InfrAserMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'aslist/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'aser_numb';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [aserNumb, setAserNumb] = useState("");
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const aserNumbChange = useCallback((e) => { setAserNumb(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, aserNumb: aserNumb };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, aserNumb, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: 'A/S 접수번호', value: aserNumb, valueChange: aserNumbChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [aserNumb, aserNumbChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-infr'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'AS이력 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    dateValue={dateValue}
                    dateValueChange={dateValueChange}
                    duraYesn={duraYesn}
                    duraYesnChange={duraYesnChange}
                    ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: true, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "aser_numb", caption: "A/S접수번호" })}
                    {StatCol({ dataField: "aser_gubu", caption: "진행상황", customCell: aserStateCell })}
                    {CommCol({ dataField: "stin_name", caption: "충전소명" })}
                    {CommCol({ dataField: "cpin_idno", caption: "충전기ID" })}
                    {CommCol({ dataField: "cpin_modl", caption: "충전기모델명" })}
                    {CommCol({ dataField: "clie_name", caption: "신청고객명" })}
                    {CommCol({ dataField: "clie_teln", caption: "신청고객연락처" })}
                    {DateCol({ dataField: "acce_date", caption: "접수일" })}
                    {CommCol({ dataField: "acce_name", caption: "접수자" })}
                    {CommCol({ dataField: "acce_memo", caption: "접수증상" })}
                    {CommCol({ dataField: "comp_name", caption: "담당자" })}
                    {DateCol({ dataField: "comp_date", caption: "완료일" })}
                    {CommCol({ dataField: "comp_memo", caption: "조치내용" })}
                    {CommCol({ dataField: "aser_memo", caption: "특이사항" })}
                    {CommCol({ dataField: "user_name", caption: "작성자" })}
                    {DateCol({ dataField: "user_date", caption: "작성일" })}
                    {CommCol({ dataField: "modi_name", caption: "수정자" })}
                    {DateCol({ dataField: "modi_date", caption: "수정일" })}
                </PagingGrid>
                <InfrAserInsert
                    refresh={handleSearch}
                    insertApi={infoInsertApi}
                    visible={infoInsertPopupVisible}
                    hiding={infoInsertPopupHide}
                />
                <InfrAserUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />
            </div>
        </>
    );
};