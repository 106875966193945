var userInfo = {
  userIdno: '',
  userName: '',
  userCard: '',
  phonNumb: '',
  carsNumb: '',
  userLeve: 3,
  compCode: '',
}

export default userInfo;
