import MoniDashStat from "./dash/MoniDash-page";
import MoniMapsStat from "./maps/MoniMaps-page";

export const moni = ({ acce, code }) => {
    const cate = 'mon';

    const comp = {
        dashStat: <MoniDashStat acce={acce} cate={cate} code={`${code}1`} mainTitle={'대쉬보드'} />,
        mapsStat: <MoniMapsStat acce={acce} cate={cate} code={`${code}2`} mainTitle={'설치위치'} />
    }

    return comp;
}