import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    owne_idno: '',
    cred_numb: '0000000000000000',
    cred_name: '',
    cred_yesn: false,
};

export function UserCredUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
    
            if (seleData) {
                const targData = {
                    owne_idno: seleData.owne_idno,
                    cred_view: seleData.cred_view,
                    cred_numb: seleData.cred_numb,
                    cred_name: seleData.cred_name,
                    cred_yesn: seleData.cred_yesn,
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            owneIdno: data.owne_idno || '',
            credNumb: data.cred_numb || '0000000000000000',
            credName: data.cred_name || '',
            credYesn: data.cred_yesn ? 1 : 0,
        };

        await fwUtil.apis.submit(updateApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'결제카드 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 600}
            >
                <GroupItem>
                    {FormText({ value: data.cred_name, onValueChange: updateField('cred_name'), label: '카드사명', required: true, disabled: true })}
                    {FormText({ value: data.cred_view, onValueChange: updateField('cred_view'), label: '카드번호', required: true, disabled: true })}
                    {FormText({ value: data.owne_idno, onValueChange: updateField('owne_idno'), label: '소유자ID', required: true, disabled: true })}
                    {FormBool({ value: data.cred_yesn, onValueChange: updateField('cred_yesn'), label: '주 사용카드' })}
                </GroupItem>
            </PopupForm>
        </>
    );
}