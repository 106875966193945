import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupAddr, PopupForm, PopupGrid, PopupMaps, PopupWarn } from '../../../../script/components/popup/popup';
import { FormBool, FormBtns, FormDate, FormGrid, FormNumb, FormRegx, FormSelc, FormText, FormTran } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { getStatBusiList, getStatUserList, getStatTyp1List, getStatTyp2List } from '../../../../../api/cms/set-chrg';

const defaultStar = new Date(2000, 1, 1, 0, 0, 0);
const defaultEnds = new Date(2000, 1, 1, 24, 0, 0);

const defaultData = {
    station_id: '',
    station_name: '',
    stat_addr: '',
    stat_adda: '',
    stat_addb: '',
    station_address: '',
    install_date: fwUtil.date.getsText.date(),
    stat_xint: '0',
    stat_yint: '0',
    modl_numb: '',
    stat_envi: '',
    stat_enab: '운영',
    stat_alls: false,
    stat_star: defaultStar,
    stat_ends: defaultEnds,
    stat_typ1: '',
    stat_typ2: '',
    inst_same: false,
    stat_date: null,
    pwrs_func: '',
    pwrs_size: '',
    pwrs_type: '',
    pwrs_valu: '',
    park_kwon: 0,
    park_cont: 0,
    park_yesn: false,
    stat_idno: '',
    stat_name: '',
    clie_code: '',
    clie_name: '',
};

const statEnabList = ['운영', '미운영'];
const pwrsFuncList = ['가공인입', '지중인입'];
const pwrsTypeList = ['고압', '저압'];

const compKeyExpr = ['clie_code', 'id'];
const idnoKeyExpr = 'id';

export function ChrgStatInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 팝업 그리드
    const compGridRef = useRef(null);
    const idnoGridRef = useRef(null);
    const [compData, setCompData] = useState([]);
    const [idnoData, setIdnoData] = useState([]);
    const compGridUpdate = useCallback((e) => setCompData(e), []);
    const idnoGridUpdate = useCallback((e) => setIdnoData(e), []);
    // 그리드 팝업
    const [compPopupVisible, setCompPopupVisible] = useState(false);
    const [idnoPopupVisible, setIdnoPopupVisible] = useState(false);
    const compPopupHiding = useCallback(() => setCompPopupVisible(false), []);      
    const idnoPopupHiding = useCallback(() => setIdnoPopupVisible(false), []);      
    // 지도
    const [mapsRefs, setMapsRefs] = useState(null);
    const mapsRefsSetting = useCallback((e) => setMapsRefs(e), []);
    const [addrData, setAddrData] = useState({ addr: '', xint: '', yint: '', adda: '', addb: '' })
    const addrDataUpdate = useCallback((e) => setAddrData(e), []);
    const zoomControler = { able: true, position: 'RIGHT' };
    // 선택 항목
    const [statTyp1List, setStatTyp1List] = useState([]);
    const [statTyp2List, setStatTyp2List] = useState([]);
    const statTyp1ListChange = useCallback((e) => setStatTyp1List(e), []);
    const statTyp2ListChange = useCallback((e) => setStatTyp2List(e), []);
    // 팝업
    const [checPopupVisible, setChecPopupVisible] = useState(false);
    const [addrPopupVisible, setAddrPopupVisible] = useState(false);
    const [mapsPopupVisible, setMapsPopupVisible] = useState(false);
    const checPopupHide = useCallback(() => setChecPopupVisible(false), []);
    const addrPopupHide = useCallback(() => setAddrPopupVisible(false), []);
    const mapsPopupHide = useCallback(() => {
        setMapsPopupVisible(false)
        setMapsRefs(null);
    }, []);

    // 그리드 조회
    const compHandleSearch = useCallback(async () => {
        const res = await getStatBusiList();
        compGridUpdate(res);
    }, [compGridUpdate]);  

    const idnoHandleSearch = useCallback(async () => {
        const res = await getStatUserList();
        idnoGridUpdate(res);
    }, [idnoGridUpdate]);      

    // 지도 팝업 확인
    const warnPopupConfirm = useCallback(() => {
        dataUpdate({ ...data, 'station_address': addrData.addr, 'stat_adda': addrData.adda, 'stat_addb': addrData.addb,'stat_xint': addrData.xint, 'stat_yint': addrData.yint });
        checPopupHide();
        mapsPopupHide();
        addrPopupHide();
    }, [data, addrData, dataUpdate, checPopupHide, mapsPopupHide, addrPopupHide]);

    const addrPopupConfirm = useCallback((postData) => {
        if (!postData || !postData.address) {
            fwUtil.aler.toast.erro('주소 정보를 가져오는 데 실패하였습니다.');
            return;
        };
        addrDataUpdate(prevState => ({ ...prevState, addr: postData.address }));
        setMapsPopupVisible(true);
    }, [addrDataUpdate, setMapsPopupVisible]);

    const mapsPopupConfirm = useCallback(async (postData) => {
        const addr = postData?.addr;
        const xint = postData?.xint;
        const yint = postData?.yint;
        const adda = postData?.adda;
        const addb = postData?.addb;

        if (!addr || !xint || !yint || !adda || !addb) {
            fwUtil.aler.toast.erro('주소 정보를 가져오는 데 실패하였습니다.');
            return;
        }

        addrDataUpdate({ addr: addr, xint: xint, yint: yint, adda: adda, addb: addb });
        setChecPopupVisible(true);
    }, [addrDataUpdate]);
    
    const addrButton = {
        name: 'addr-search-btn',
        loca: 'after',
        text: '주소찾기',
        mode: 'outlined',
        class: 'fw-addr-search-btn',
        click: () => setAddrPopupVisible(true),
    };

    const fetchTyp1Set = useCallback(async () => {
        try {
            const [statTyp1Result] = await Promise.all([getStatTyp1List()]);
            statTyp1ListChange(statTyp1Result);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro();
        }
    }, [statTyp1ListChange]);

    const fetchTyp2Set = useCallback(async (value) => {
        const sendData = { mainCode: fwUtil.conv.split(value) };
        try {
            const [statTyp2Result] = await Promise.all([getStatTyp2List(sendData)]);
            statTyp2ListChange(statTyp2Result);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro();
        }
    }, [statTyp2ListChange]);

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchTyp1Set();
        dataUpdate(defaultData);
    }, [fetchTyp1Set, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        if (field === 'stat_time') {
            if (value || value.length === 2) {
                console.log(value)
                dataUpdate({ ...data, 'stat_star': value[0], 'stat_ends': value[1] });
                return;
            }
            return;
        }

        if (field === 'stat_alls') {
            if (value) {
                dataUpdate({ ...data, 'stat_star': defaultStar, 'stat_ends': defaultEnds, [field]: value });
                return;
            }
        }

        if (field === 'inst_same') {
            if (value) {
                dataUpdate({ ...data, 'stat_date': data.install_date, [field]: value });
                return;
            }
        } 

        if (field === 'park_yesn') {
            if (!value) {
                dataUpdate({ ...data, 'park_kwon': 0, [field]: value });
                return;
            }
        } 

        if (field === 'stat_typ1') {
            fetchTyp2Set(value);
            dataUpdate({ ...data, [field]: value, 'stat_typ2': '' });
            return;
        }

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);

        const postData = {
            stationId: data.station_id || '',
            stationName: data.station_name || '',
            statAddr: data.stat_addr || '',
            statAdda: data.stat_adda || '',
            statAddb: data.stat_addb || '',
            stationAddress: data.station_address || '',
            installDate: data.install_date || fwUtil.date.getsText.date(),
            statXint: data.stat_xint || 0,
            statYint: data.stat_yint || 0,
            modlNumb: data.modl_numb || '',
            statEnvi: data.stat_envi || '',
            statEnab: data.stat_enab === '운영' ? 1 : 0,
            statAlls: data.stat_alls || false,
            statStar: data.stat_star ? fwUtil.date.convTime.fmHHmm(data.stat_star) : null,
            statEnds: data.stat_ends && fwUtil.date.convTime.fmHHmm(data.stat_ends) === '00:00' ? '24:00' : null,
            statTyp1: data.stat_typ1 || '',
            statTyp2: data.stat_typ2 || '',
            statDate: data.stat_date || null,
            pwrsFunc: data.pwrs_func === '가공인입' ? 0 : 1,
            pwrsSize: data.pwrs_size || '',
            pwrsType: data.pwrs_type === '고압' ? 0 : 1,
            pwrsValu: data.pwrs_valu || '',
            parkKwon: data.park_kwon || 0,
            parkCont: data.park_cont || 0,
            parkYesn: data.park_yesn ? 1 : 0,
            statIdno: data.stat_idno || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',            
        };

        await fwUtil.apis.check(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    const compPopupOnShowing = useCallback(() => {
        compHandleSearch();
    }, [compHandleSearch]);

    const idnoPopupOnShowing = useCallback(() => {
        idnoHandleSearch();
    }, [idnoHandleSearch]);    

    const compOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'clie_code': e.data.clie_code, 'clie_name': e.data.clie_name });
        compPopupHiding();
    }, [data, dataUpdate, compPopupHiding]);

    const idnoOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'stat_idno': e.data.id, 'stat_name': e.data.user_name });
        idnoPopupHiding();
    }, [data, dataUpdate, idnoPopupHiding]);    

    const compGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'사업자'}
                role={'목록'}
                gridDataSource={compData}
                gridRef={compGridRef}
                mainKey={compKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={compOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명" })}
                {CommCol({ dataField: "addr_knam", caption: "주소" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}
                {CommCol({ dataField: "id", caption: "사용자ID" })}
                {CommCol({ dataField: "user_name", caption: "사용자명" })}
            </ScrollGrid>
        )
    }, [compData, compGridRef, compKeyExpr, compOnRowDblClick, isXSmall, isSmall]);

    const idnoGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'계정'}
                role={'목록'}
                gridDataSource={idnoData}
                gridRef={idnoGridRef}
                mainKey={idnoKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={idnoOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "id", caption: "사용자ID" })}
                {CommCol({ dataField: "user_name", caption: "사용자명" })}
                {CommCol({ dataField: "mail_idno", caption: "사용자메일" })}
            </ScrollGrid>
        )
    }, [idnoData, idnoGridRef, idnoKeyExpr, idnoOnRowDblClick, isXSmall, isSmall]);  

    return (
        <>
            <PopupGrid
                title={'사업자명 조회'}
                visible={compPopupVisible}
                shown={compPopupOnShowing}
                hiding={compPopupHiding}
            >
                {compGrid}
            </PopupGrid>
            <PopupGrid
                title={'소유자명 조회'}
                visible={idnoPopupVisible}
                shown={idnoPopupOnShowing}
                hiding={idnoPopupHiding}
            >
                {idnoGrid}
            </PopupGrid>            
            <PopupForm
                title={'충전소 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? '100%' : isMedium ? 900 : 1000}
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>

                    {FormGrid({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '사업자명', required: true, onClick: () => setCompPopupVisible(true) })}
                    {FormGrid({ value: data.stat_idno, onValueChange: updateField('stat_idno'), label: '소유계정', required: true, onClick: () => setIdnoPopupVisible(true) })}
                    {FormRegx({ value: data.station_name, onValueChange: updateField('station_name'), label: '충전소명', required: true, rule: /^[a-zA-Z가-힣0-9\s]{1,100}$/, placeholder: '영문, 한글, 숫자 100자' })}
                    {FormRegx({ value: data.station_id, onValueChange: updateField('station_id'), label: '충전소ID', required: true, rule: /^[a-zA-Z0-9]{1,20}$/, placeholder: '영문, 숫자 12자' })}
                    {FormRegx({ value: data.stat_envi, onValueChange: updateField('stat_envi'), label: '환경부 충전소ID', required: true, rule: /^[a-zA-Z0-9]{1,6}$/, placeholder: '영문, 숫자 6자' })}
                    {FormSelc({ value: data.stat_enab, onValueChange: updateField('stat_enab'), label: '운영여부', required: true, items: statEnabList })}
                    <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                        {FormTran({ value: [data.stat_star, data.stat_ends], onValueChange: updateField('stat_time'), allValue: data.stat_alls, onAllValueChange: updateField('stat_alls'), label: '사용가능시간', height: 80, disabled: data.stat_alls })}
                    </GroupItem>
                    <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                        {FormBtns({ value: data.station_address, onValueChange: updateField('station_address'), label: '주소', button: addrButton, required: true, disabled: true })}
                        {FormText({ value: data.stat_addr, onValueChange: updateField('stat_addr'), label: '상세주소' })}
                    </GroupItem>
                    {FormText({ value: data.stat_adda, onValueChange: updateField('stat_adda'), label: '권역명', required: true, disabled: true })}
                    {FormText({ value: data.stat_addb, onValueChange: updateField('stat_addb'), label: '시군구명', required: true, disabled: true })}
                    {FormText({ value: data.stat_xint, onValueChange: updateField('stat_xint'), label: '위도', required: true, disabled: true })}
                    {FormText({ value: data.stat_yint, onValueChange: updateField('stat_yint'), label: '경도', required: true, disabled: true })}
                    {FormSelc({ value: data.stat_typ1, onValueChange: updateField('stat_typ1'), label: '시설 구분(대)', required: true, items: statTyp1List })}
                    {FormSelc({ value: data.stat_typ2, onValueChange: updateField('stat_typ2'), label: '시설 구분(소)', required: true, items: statTyp2List })}
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 1 : 2}>
                        {FormDate({ value: data.install_date, onValueChange: updateField('install_date'), label: '충전소 설치일', required: true })}
                    </GroupItem>
                    {FormDate({ value: data.stat_date, onValueChange: updateField('stat_date'), label: '운영시작일', disabled: data.inst_same })}
                    {FormBool({ value: data.inst_same, onValueChange: updateField('inst_same'), text: '설치일과 동일', defaultLabel: true })}
                    {FormSelc({ value: data.pwrs_func, onValueChange: updateField('pwrs_func'), label: '수전방식', items: pwrsFuncList })}
                    {FormRegx({ value: data.pwrs_size, onValueChange: updateField('pwrs_size'), label: '수전용량', rule: /^[a-zA-Z0-9]{0,10}$/, placeholder: '영문, 숫자 10자' })}
                    {FormSelc({ value: data.pwrs_type, onValueChange: updateField('pwrs_type'), label: '전압종류', items: pwrsTypeList })}
                    {FormRegx({ value: data.pwrs_valu, onValueChange: updateField('pwrs_valu'), label: '계약전력', rule: /^[a-zA-Z0-9]{0,10}$/, placeholder: '영문, 숫자 10자' })}
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 1 : 2}>
                        {FormNumb({ value: data.park_cont, onValueChange: updateField('park_cont'), label: '주차면 수', max: 9999, placeholder: '숫자 4자' })}
                    </GroupItem>
                    {FormNumb({ value: data.park_kwon, onValueChange: updateField('park_kwon'), label: '주차료', max: 9999999999, disabled: !data.park_yesn, placeholder: '숫자 10자' })}
                    {FormBool({ value: data.park_yesn, onValueChange: updateField('park_yesn'), text: '주차료 유무', defaultLabel: true })}
                </GroupItem>
            </PopupForm>
            <PopupWarn
                visible={checPopupVisible}
                hiding={checPopupHide}
                warning={
                    <div className='fw-maps-info'>
                        <div>주소 : {addrData.addr}</div>
                        <div>권역명 : {addrData.adda}</div>
                        <div>시군구명 : {addrData.addb}</div>
                        <div>경도 : {addrData.xint}</div>
                        <div>위도 : {addrData.yint}</div>
                    </div>
                }
                message={'해당 위치로 등록하시겠습니까?'}
                confirm={warnPopupConfirm}
            />
            <PopupAddr
                visible={addrPopupVisible}
                hiding={addrPopupHide}
                confirm={addrPopupConfirm}
            />
            <PopupMaps
                visible={mapsPopupVisible}
                hiding={mapsPopupHide}
                confirm={mapsPopupConfirm}
                defaultAddr={addrData?.addr}
                mapInstance={mapsRefs}
                setMapInstance={mapsRefsSetting}
                zoom={{ controler: zoomControler, max: 12 }}
            />
        </>
    );
}