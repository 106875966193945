import DataSource from 'devextreme/data/data_source';
import { fwUtil } from '../util';

function getSelectedSingleData(grid) {
    const selectedRowData = grid?.current?.instance?.getSelectedRowsData() || [];
    if (selectedRowData.length > 0) {
        return selectedRowData[0];
    }

    return null;
};

function getSelectedMultiData(grid) {
    const selectedRowData = grid?.current?.instance?.getSelectedRowsData() || [];
    if (selectedRowData.length > 0) {
        return selectedRowData;
    }

    return null;
};

function getSelectedRowsKey(grid) {
    const selectedRowKey = grid?.current?.instance?.getSelectedRowKeys();

    if (fwUtil.conv.check.arr(selectedRowKey)) {
        if (selectedRowKey.length >= 2) {
            return selectedRowKey;
        } else {
            return selectedRowKey[0];
        }
    }

    return null;
};

function getSelectedLastKey(grid) {
    const selectedRowKey = grid?.current?.instance?.getSelectedRowKeys();

    if (fwUtil.conv.check.arr(selectedRowKey)) {
        if (selectedRowKey.length >= 2) {
            return selectedRowKey[selectedRowKey.length - 1];
        } else {
            return selectedRowKey[0];
        }
    }

    return null;
};

const getDataSource = (defalutValue) => {
    const dataSource = new DataSource({
        store: [],
        key: 'rowKey',
        load: function() {
            return this.store;
        },
        insert: function(values) {
            const rowKey = getRandomKeys();
            defalutValue && defalutValue(this.store, values);
            values.rowKey = rowKey;
            if (values.init) {
                this.store = [];
                return;
            } else if (this.store) {
                this.store.push(values);
            } else {
                this.store = [values]
            }
        },
        remove: function(key) {
            const index = this.store.findIndex(item => item.rowKey === key);
            if (index !== -1) {
                this.store.splice(index, 1);
            }
        },
        update: function(key, values) {
            const index = this.store.findIndex(item => item.rowKey === key);
            if (index !== -1) {
                this.store[index] = { ...this.store[index], ...values };
            }
        },
    });

    return dataSource;
};

export function getRandomKeys() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export function getIncreseNum(s) {
    if (!s || !isValidPattern(s)) return "";

    const prefix = s.slice(0, -2);
    const numPart = s.slice(-2);

    if (isDigit(numPart[0]) && isDigit(numPart[1])) {
        let num = parseInt(numPart, 10);
        if (num === 90) {
            num = 10;
            return incrementPrefix(prefix) + padNumber(num);
        } else {
            num += 10;
            return prefix + padNumber(num);
        }
    } else {
        return "";
    }
};

function incrementPrefix(prefix) {
    if (!prefix) return "a";
    
    const lastChar = prefix.slice(-1);
    const newPrefix = prefix.slice(0, -1);
    
    if (isAlpha(lastChar)) {
        const newChar = incrementChar(lastChar);
        return newPrefix + newChar;
    } else {
        return "";
    }
};

function incrementChar(c) {
    if (c === 'z') {
        return 'a';
    } else if (c === 'Z') {
        return 'A';
    } else {
        return String.fromCharCode(c.charCodeAt(0) + 1);
    }
};

function isDigit(c) {
    return c >= '0' && c <= '9';
};

function isAlpha(c) {
    return (c >= 'a' && c <= 'z') || (c >= 'A' && c <= 'Z');
};

function isValidPattern(s) {
    if (s.length < 3) return false;
    const prefix = s.slice(0, -2);
    const numPart = s.slice(-2);

    return isDigit(numPart[0]) && isDigit(numPart[1]) && (prefix === "" || isDigit(prefix[0]) || isAlpha(prefix[prefix.length - 1]));
};

function padNumber(num) {
    return num.toString().padStart(2, '0');
};


export const get = {
    sKey: getSelectedRowsKey,
    lKey: getSelectedLastKey,
    sData: getSelectedSingleData,
    mData: getSelectedMultiData,
    dataSource: getDataSource,
    randomKeys: getRandomKeys,
    increseNum: getIncreseNum,
};

function singleSelectRow(grid, keys, selected) {
    if (grid.current.instance) {
        grid.current.instance.deselectAll();
        if (!selected) {
            grid.current.instance.selectRows(keys, true);
        }
    }
};

function multipleSelectRow(grid, keys, selected) {
    if (grid.current.instance) {
        if (!selected) {
            grid.current.instance.selectRows(keys, true);
        } else {
            grid.current.instance.deselectRows(keys);
        }
    }
};

export const sel = {
    sRow: singleSelectRow,
    mRow: multipleSelectRow,
};