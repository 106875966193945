// CommCodeMana - 기준정보 - 공통코드 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { getCommCodeList } from '../../../../../api/cms/set-comm';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { CodeMainManaInsert } from './MainMana-insert';
import { CodeMainManaUpdate } from './MainMana-update';
import { CodeSubsManaInsert } from './SubsMana-insert';
import { CodeSubsManaUpdate } from './SubsMana-update';
import { fwUtil } from '../../../../script/util';

export default function CommCodeMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const mainMiddleApi = 'code/main';
    const subsMiddleApi = 'code/subs';
    const mainSearchApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'search');
    const mainInsertApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'insert');
    const mainUpdateApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'update');
    const subsSearchApi = fwUtil.apis.create(acce, cate, subsMiddleApi, 'search');
    const subsInsertApi = fwUtil.apis.create(acce, cate, subsMiddleApi, 'insert');
    const subsUpdateApi = fwUtil.apis.create(acce, cate, subsMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = 'main_code';
    const subsKey = ['main_code', 'subs_code'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), [setSubsGridData]);
    // 검색 조건
    const [bizsGubu, setBizsGubu] = useState();
    const [mainCode, setMainCode] = useState("");
    const [gubuList, setGubuList] = useState(['선택하세요']);
    const bizsGubuChange = useCallback((e) => { setBizsGubu(e) }, []);
    const mainCodeChange = useCallback((e) => { setMainCode(e) }, []);
    const gubuListChange = useCallback((e) => { setGubuList(e) }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    const fetchSet = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([getCommCodeList('search')]);
            gubuListChange(gubuResult);
            bizsGubuChange(gubuResult[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro();
        }
    }, [gubuListChange, bizsGubuChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { bizsGubu: bizsGubu, mainCode: mainCode };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [bizsGubu, mainCode, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { mainCode: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);    

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    useEffect(() => {
        fetchSet();
    }, [fetchSet]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch, bizsGubu])

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '코드구분', value: bizsGubu, valueChange: bizsGubuChange, search: mainHandleSearch, items: gubuList },
        { type: 'text', locate: 'before', caption: '관리코드명', value: mainCode, valueChange: mainCodeChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [bizsGubu, bizsGubuChange, gubuList, mainCode, mainCodeChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                default:
                    break;
            }
        };

        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }

            if (!subsSelectedRowKey) {
                fwUtil.aler.toast.warn('상세코드를 선택 후 시도해주세요.');
                return;
            }

            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('관리코드를 선택 후 시도해주세요.');
            return;
        }

        if (type === 'main') {
            handleMainAction();
        } else if (type === 'subs') {
            handleSubsAction();
        }

    }, [mainGridRef, subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-comm'} title={title} />
            <div id={'fw-page-content'}>
                <MainGrid
                    title={'관리코드 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={mainGridRef}
                    gridDataSource={mainGridData}
                    onRowClick={mainGridClick}
                    ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "main_gubu", caption: "코드구분", width: 100 })}
                    {CommCol({ dataField: "main_code", caption: "관리코드", width: 100, allowFiltering: false })}
                    {CommCol({ dataField: "main_name", caption: "관리코드명", allowFiltering: false })}
                    {BoolCol({ dataField: "main_yesn", caption: "사용여부" })}
                    {CommCol({ dataField: "main_memo", caption: "비고", allowFiltering: false, allowSorting: false })}
                </MainGrid>
                <CodeMainManaInsert
                    refresh={mainHandleSearch}
                    insertApi={mainInsertApi}
                    visible={mainInsertPopupVisible}
                    hiding={mainInsertPopupHide}
                />
                <CodeMainManaUpdate
                    refresh={mainHandleSearch}
                    mainGrid={mainGridRef}
                    updateApi={mainUpdateApi}
                    visible={mainUpdatePopupVisible}
                    hiding={mainUpdatePopupHide}
                />
                <SubsGrid
                    title={'상세코드 현황'}
                    gridSize={gridSize}
                    mainKey={subsKey}
                    gridRef={subsGridRef}
                    gridDataSource={subsGridData}
                    toolbar={{ default: false }}
                    ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "subs_code", caption: "상세코드", width: 100 })}
                    {CommCol({ dataField: "subs_name", caption: "상세코드명", width: 200 })}
                    {BoolCol({ dataField: "subs_yesn", caption: "사용여부" })}
                    {CommCol({ dataField: "subs_memo", caption: "비고" })}
                </SubsGrid>
                <CodeSubsManaInsert
                    refresh={subsHandleSearch}
                    mainGrid={mainGridRef}
                    insertApi={subsInsertApi}
                    visible={subsInsertPopupVisible}
                    hiding={subsInsertPopupHide}
                />
                <CodeSubsManaUpdate
                    refresh={subsHandleSearch}
                    mainGrid={subsGridRef}
                    updateApi={subsUpdateApi}
                    visible={subsUpdatePopupVisible}
                    hiding={subsUpdatePopupHide}
                />
            </div>
        </>
    );
};