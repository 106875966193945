import { fwUtil } from '../../util';

export const getAlertData = (selectedData) => {
    if (fwUtil.conv.check.obj(selectedData)) {
        if (Object.keys(selectedData).length >= 2) {
            return Object.values(selectedData);
        }
    } else {
        return  [selectedData];
    }   
};