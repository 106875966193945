// ChrgPricMana - 충전 관리 - 충전단가 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { ChrgPricInsert } from './InfoMana-insert';
import { ChrgPricUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

export default function ChrgPricMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const infoMiddleApi = 'price/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole? " " + mainRole : ""}`;    
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = ['charge_id', 'apply_date', 'apply_yn'];
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);  
    // 검색 조건 
    const [statName, setStatName] = useState("");
    const [chrgMode, setChrgMode] = useState("");
    const statNameChange = useCallback((e) => { setStatName(e) }, []);
    const clieSanoChange = useCallback((e) => { setChrgMode(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { stationName: statName, model: chrgMode  };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef);
    }, [statName, chrgMode, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '충전소명', value: statName, valueChange: statNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '모델명', value: chrgMode, valueChange: clieSanoChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [statName, statNameChange, chrgMode, clieSanoChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
        <ContentHeader icon={'fw-chrg'} title={title} />  
        <div id={'fw-page-content'}>
            <PagingGrid
                title={'충전단가 현황'}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >            
                {CommCol({ dataField: "station_name", caption: "사업장명" })}
                {CommCol({ dataField: "model", caption: "모델명" })}
                {CommCol({ dataField: "charge_id", caption: "충전기 ID" })}
                {DateCol({ dataField: "apply_date", caption: "적용일자" })}
                {CommCol({ dataField: "view_yn", caption: "회원구분" })}
                {DeciCol({ dataField: "h00", caption: "00시", suffix: "원" })}
                {DeciCol({ dataField: "h01", caption: "01시", suffix: "원" })}
                {DeciCol({ dataField: "h02", caption: "02시", suffix: "원" })}
                {DeciCol({ dataField: "h03", caption: "03시", suffix: "원" })}
                {DeciCol({ dataField: "h04", caption: "04시", suffix: "원" })}
                {DeciCol({ dataField: "h05", caption: "05시", suffix: "원" })}
                {DeciCol({ dataField: "h06", caption: "06시", suffix: "원" })}
                {DeciCol({ dataField: "h07", caption: "07시", suffix: "원" })}
                {DeciCol({ dataField: "h08", caption: "08시", suffix: "원" })}
                {DeciCol({ dataField: "h09", caption: "09시", suffix: "원" })}
                {DeciCol({ dataField: "h10", caption: "10시", suffix: "원" })}
                {DeciCol({ dataField: "h11", caption: "11시", suffix: "원" })}
                {DeciCol({ dataField: "h12", caption: "12시", suffix: "원" })}
                {DeciCol({ dataField: "h13", caption: "13시", suffix: "원" })}
                {DeciCol({ dataField: "h14", caption: "14시", suffix: "원" })}
                {DeciCol({ dataField: "h15", caption: "15시", suffix: "원" })}
                {DeciCol({ dataField: "h16", caption: "16시", suffix: "원" })}
                {DeciCol({ dataField: "h17", caption: "17시", suffix: "원" })}
                {DeciCol({ dataField: "h18", caption: "18시", suffix: "원" })}
                {DeciCol({ dataField: "h19", caption: "19시", suffix: "원" })}
                {DeciCol({ dataField: "h20", caption: "20시", suffix: "원" })}
                {DeciCol({ dataField: "h21", caption: "21시", suffix: "원" })}
                {DeciCol({ dataField: "h22", caption: "22시", suffix: "원" })}
                {DeciCol({ dataField: "h23", caption: "23시", suffix: "원" })}                
            </PagingGrid>
            <ChrgPricInsert
                refresh={handleSearch}
                insertApi={infoInsertApi}
                visible={infoInsertPopupVisible}
                hiding={infoInsertPopupHide}
            />
            <ChrgPricUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                updateApi={infoUpdateApi}
                visible={infoUpdatePopupVisible}
                hiding={infoUpdatePopupHide}
            />                            
        </div>        
        </>
    );
};