import { fwUtil } from "../../../../script/util";

function transDaysData(data) {
    if (data && data.length > 0 && data[0].date_time) {
        let seconds1;
        let seconds2;
        let timeDifferenceInSeconds;
        let timeDifference;

        if (data[0].usageTime1 && data[0].usageTime2) {
            seconds1 = fwUtil.date.convTime.toSecs()(data[0].usageTime1);
            seconds2 = fwUtil.date.convTime.toSecs()(data[0].usageTime2);
            timeDifferenceInSeconds = Math.abs(seconds1 - seconds2);
            timeDifference = fwUtil.date.convTime.toTime(timeDifferenceInSeconds);
        }

        const convertedData = [
            {
                "DayType": "전일",
                "ChargerCount": data[0].usageCount2,
                "ChargerKW": data[0].usePower2,
                "ChargerPayment": data[0].resultPrice2,
                "ChargerTime": data[0].usageTime2
            },
            {
                "DayType": "금일",
                "ChargerCount": data[0].usageCount1,
                "ChargerKW": data[0].usePower1,
                "ChargerPayment": data[0].resultPrice1,
                "ChargerTime": data[0].usageTime1
            },
            {
                "DayType": "차이(전일대비)",
                "ChargerCount": data[0].usageCount1 - data[0].usageCount2,
                "ChargerKW": data[0].usePower1 - data.usePower2,
                "ChargerPayment": data[0].resultPrice1 - data[0].resultPrice2,
                "ChargerTime": timeDifference
            }
        ];
        return convertedData;
    }
    return [];
};

function transRptgData(data) {
    if (!data) {
        return [];
    }
    return data.filter(item => item.usageTime !== undefined && item.usageTime !== null && item.usageTime !== "")
        .map(item => ({
            date_time: item.usageTime,
            usePower: item.usePower,
            usageCount: item.usageCount,
            resultPrice: item.resultPrice
        }));
}

function transRptsData(data) {
    let result = [];

    data.forEach(item => {
        const { ChartArea, DayOfWeek, ChargeKW, ChargePay, ChargeCount } = item;

        let weekObj = result.find(obj => obj.ChartWeek === DayOfWeek);
        if (!weekObj) {
            weekObj = { ChartWeek: DayOfWeek };
            result.push(weekObj);
        }

        weekObj[`C${ChartArea}ChargeKW`] = ChargeKW;
        weekObj[`C${ChartArea}ChargePayment`] = ChargePay;
        weekObj[`C${ChartArea}ChargeCount`] = ChargeCount;
    });

    return result;
};

function transRptcData(data) {
    if (!data) {
        return [];
    }
    return data.reduce((acc, currentArray) => {
        const index = data.indexOf(currentArray);
        const transformedData = transformData(currentArray, index);
        return [...acc, ...transformedData];
    }, []);
}

function transformData(data, index) {
    const firstDayOfWeek = fwUtil.date.getsText.sWeek(data[0].usageTime);

    const chartArea = index < 7 ? 0 : 1;

    const transformedWeekData = data.map(item => ({
        ChartArea: chartArea,
        DayOfWeek: firstDayOfWeek,
        ChargeKW: item.usePower,
        ChargePay: item.resultPrice,
        ChargeCount: item.usageCount,
        usageTime: item.usageTime,
    }));

    return transformedWeekData;
};

export const moniDashFunc = {
    transDaysData: transDaysData,
    transRptgData: transRptgData,
    transRptcData: transRptcData,
    transRptsData: transRptsData,
};