import React, { useCallback, useEffect } from 'react';
import mapUtil from './map-util';
import './map.scss';

const { kakao } = window;

const CommMaps = (props) => {
    const {
        title,
        setMapInstance,
        setGeoInstance,
        view, zoom,
        children
    } = props;

    const id = `fw-maps-${title}`;
    
    /* eslint-disable */
    const mapsSets = useCallback(() => {
        if (!kakao) {
            window.location.reload();
            return;
        };

        const container = document.getElementById(id);
        const options = {
            center: new kakao.maps.LatLng(33.450701, 126.570667),
            level: 3
        };

        if (container) {
            // instance set
            const map = new kakao.maps.Map(container, options);
            const geocoder = new kakao.maps.services.Geocoder();
            setMapInstance(map);
            setGeoInstance(geocoder);

            // setting
            mapUtil.set.view(map, view);
            mapUtil.set.zoom(map, zoom);
        }
    }, []);
    /* eslint-enable */

    useEffect(() => {
        mapsSets();
    }, [mapsSets]);

    return (
        <>
        <div id={id} className='fw-map'>
            {children}           
        </div>
        </>
    );
};

export { CommMaps };
