import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupAddr, PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormRegx, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    clie_code: '',
    enab_yesn: true,
    damd_name: '',
    dept_name: '',
    posi_name: '',
    mail_idno: '',
    damd_teln: '',
    damd_faxn: '',
    damd_cell: '',
    pres_name: '',
    clie_sano: '',
    clie_name: '',
    upta_name: '',
    jong_moks: '',
    tele_numb: '',
    faxs_numb: '',
    zips_code: '',
    addr_knam: '',
    clie_nick: '',
    envi_code: '',
    envi_keys: '',
    clie_memo: '',
};

export function ChrgBusiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 주소 팝업
    const [addrPopupVisible, setAddrPopupVisible] = useState(false);
    const addrPopupHide = useCallback(() => setAddrPopupVisible(false), []);
    const addrPopupConfirm = useCallback(async (postData) => {
        const updateedData = {
            ...data,
            zips_code: postData.zonecode,
            addr_knam: postData.address,
        };
        addrPopupHide();
        dataUpdate(updateedData);
    }, [data, addrPopupHide, dataUpdate]);
    const addrButton = {
        name: 'addr-search-btn',
        loca: 'after',
        text: '주소찾기',
        mode: 'outlined',
        class: 'fw-addr-search-btn',
        click: () => setAddrPopupVisible(true),
    };

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
            if (seleData) {
                const targData = {
                    clie_code: seleData.clie_code || '',
                    enab_yesn: seleData.enab_yesn === 1 ? true : false,
                    damd_name: seleData.damd_name || '',
                    dept_name: seleData.dept_name || '',
                    posi_name: seleData.posi_name || '',
                    mail_idno: seleData.mail_idno || '',
                    damd_teln: seleData.damd_teln || '',
                    damd_faxn: seleData.damd_faxn || '',
                    damd_cell: seleData.damd_cell || '',
                    pres_name: seleData.pres_name || '',
                    clie_sano: seleData.clie_sano || '',
                    clie_name: seleData.clie_name || '',
                    upta_name: seleData.upta_name || '',
                    jong_moks: seleData.jong_moks || '',
                    tele_numb: seleData.tele_numb || '',
                    faxs_numb: seleData.faxs_numb || '',
                    zips_code: seleData.zips_code || '',
                    addr_knam: seleData.addr_knam || '',
                    clie_nick: seleData.clie_nick || '',
                    envi_code: seleData.envi_code || '',
                    envi_keys: seleData.envi_keys || '',
                    clie_memo: seleData.clie_memo || '',
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            clieCode: data.clie_code || '',
            enabYesn: data.enab_yesn ? 1 : 0,
            damdName: data.damd_name || '',
            deptName: data.dept_name || '',
            posiName: data.posi_name || '',
            mailIdno: data.mail_idno || '',
            damdTeln: data.damd_teln || '',
            damdFaxn: data.damd_faxn || '',
            damdCell: data.damd_cell || '',
            presName: data.pres_name || '',
            clieSano: data.clie_sano || '',
            clieName: data.clie_name || '',
            uptaName: data.upta_name || '',
            jongMoks: data.jong_moks || '',
            teleNumb: data.tele_numb || '',
            faxsNumb: data.faxs_numb || '',
            zipsCode: data.zips_code || '',
            addrKnam: data.addr_knam || '',
            clieNick: data.clie_nick || '',
            enviCode: data.envi_code || '',
            enviKeys: data.envi_keys || '',
            clieMemo: data.clie_memo || '',
        };

        await fwUtil.apis.check(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'사업자 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : isMedium ? 800 : 1200}
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 3}>
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 1 : 2} cssClass='fw-form-group chrg-busi-info'>
                        {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: "거래처번호", required: true, maxLength: 4, disabled: true })}
                        {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: "사용여부" })}
                        {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: "거래처명" })}
                        {FormRegx({ value: data.pres_name, onValueChange: updateField('pres_name'), label: "대표자명", rule: /^[a-zA-Z가-힣0-9]{1,30}$/, required: true })}
                        {FormRegx({ value: data.clie_sano, onValueChange: updateField('clie_sano'), label: "사업자번호", rule: /^\d{1,15}$/, required: true })}
                        {FormRegx({ value: data.clie_nick, onValueChange: updateField('clie_nick'), label: "Vendor ID", maxLength: 2, rule: /^[a-zA-Z]{2}$/, required: true, placeholder: '영문 2자' })}
                        {FormText({ value: data.upta_name, onValueChange: updateField('upta_name'), label: "업태" })}
                        {FormText({ value: data.jong_moks, onValueChange: updateField('jong_moks'), label: "종목" })}
                        {FormRegx({ value: data.tele_numb, onValueChange: updateField('tele_numb'), label: "전화번호", required: true, rule: /^[0-9\-]{1,20}$/, placeholder: '-,숫자 20자 이내' })}
                        {FormText({ value: data.faxs_numb, onValueChange: updateField('faxs_numb'), label: "팩스번호" })}

                        <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 1 : 2}>
                            {FormText({ value: data.zips_code, onValueChange: updateField('zips_code'), label: '우편번호', button: addrButton, maxLength: 5 })}
                        </GroupItem>
                        <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                            {FormText({ value: data.addr_knam, onValueChange: updateField('addr_knam'), label: "주소", required: true })}
                        </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass='fw-form-group chrg-busi-damd'>
                        {FormText({ value: data.damd_name, onValueChange: updateField('damd_name'), label: "담당자명" })}
                        {FormText({ value: data.dept_name, onValueChange: updateField('dept_name'), label: "담당자 부서" })}
                        {FormText({ value: data.posi_name, onValueChange: updateField('posi_name'), label: "담당자 직위" })}
                        {FormRegx({ value: data.mail_idno, onValueChange: updateField('mail_idno'), label: "담당자 이메일", rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, required: true })}
                        {FormRegx({ value: data.damd_teln, onValueChange: updateField('damd_teln'), label: "담당자 전화", rule: /^[0-9\-]{1,20}$/, placeholder: '-,숫자 20자 이내' })}
                        {FormRegx({ value: data.damd_faxn, onValueChange: updateField('damd_faxn'), label: "담당자 팩스", rule: /^[0-9\-]{1,20}$/, placeholder: '-,숫자 20자 이내' })}
                        {FormRegx({ value: data.damd_cell, onValueChange: updateField('damd_cell'), label: "담당자 H.P", rule: /^[0-9\-]{1,20}$/, placeholder: '-,숫자 20자 이내' })}
                    </GroupItem>
                </GroupItem>
                <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 3}>
                    {FormRegx({ value: data.envi_code, onValueChange: updateField('envi_code'), label: "환경부 기관코드", rule: /^[a-zA-Z가-힣0-9]{1,50}$/ })}
                    {FormRegx({ value: data.envi_keys, onValueChange: updateField('envi_keys'), label: "환경부 인증키", rule: /^[a-zA-Z가-힣0-9]{1,200}$/ })}
                </GroupItem>
                {FormMemo({ value: data.clie_memo, onValueChange: updateField('clie_memo'), label: "메모" })}
            </PopupForm>
            <PopupAddr
                visible={addrPopupVisible}
                hiding={addrPopupHide}
                confirm={addrPopupConfirm}
            />
        </>
    );
}