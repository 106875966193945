import React from "react";
import { CheckBox, DateRangeBox } from "devextreme-react"

const ToolbarDateRangeItem = React.memo(function ToolbarDateRangeItem({ dateValue, dateValueChange, duraYesn, duraYesnChange }) {
    if (!dateValueChange) {
        return;
    }

    return (
        <div className='fw-header-date-search-panel fw-flex-box row'>
            <DateRangeBox
                width={270}
                value={dateValue}
                onValueChanged={dateValueChange}
                acceptCustomValue={false}
                labelMode='hidden'
                disabled={!duraYesn}
                stylingMode='outlined'
                displayFormat={'yyyy-MM-dd'}
                dateSerializationFormat={'yyyy-MM-dd'}
            />
            {duraYesnChange && 
            <CheckBox
                value={duraYesn}
                onValueChanged={duraYesnChange}
                elementAttr={{ 'aria-label': 'Handle value change' }}
            />                
            }
        </div>
    )
});

export { ToolbarDateRangeItem };