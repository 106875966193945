import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';
import { fwUtil } from '../focuswin/script/util';

function AuthProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [userMenu, setMenu] = useState([]);

  // 초기 메뉴 조회
  useEffect(() => {
    (async function () {
      const userResult = await getUser();
      if (userResult.isOk) {
        setUser(userResult.data);
        setMenu(userResult.userMenu);
      } else {
        setUser(null);
        setMenu(null);
      }
      setLoading(false);
    })();
  }, []);

  const getMenu = useCallback(() => userMenu, [userMenu]);

  const getDetailAuth = useCallback((code) => {
    const userAuthData = userMenu.filter(item => item.menu_code === code);
    if (!userAuthData || userAuthData.length === 0) {
      navigate('/home');
      fwUtil.aler.toast.erro('잘못된 접근입니다');
      return [{ auth_acce: false }]
    }
    return userAuthData;
  }, [userMenu, navigate]);

  const authCheck = async (setLoading, setAuthList, code) => {
    const authData = await getDetailAuth(code)[0];
    if (!authData.auth_acce) {
        navigate('/home');
        fwUtil.aler.toast.erro('잘못된 접근입니다');
        return;
    }
    setAuthList && setAuthList(authData);
    setLoading(false);
  };

  // 로그인
  const signIn = useCallback(async (userIdno, passWord) => {
    const result = await sendSignInRequest(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      setMenu(result.userMenu);
      fwUtil.aler.toast.info(`${result.data.userName}님 반갑습니다.`);
    }
    return result;
  }, []);

  // 로그아웃
  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result.isOk) {
      setUser(undefined);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setLoading, getMenu, getDetailAuth, authCheck }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
