import { ContentHeader } from "../../script/components/header/Header";
import UnderImg from '../../assets/publ-under-construction.png';
import { suppComp } from "../../../utils/default-comp";
import './UnderCons.scss';

export default function UndeConsPage() {
    return (
        <>
        <ContentHeader icon={'toolbox'} title={'작업 중'} />        
        <div id={'fw-page-content'}>
            <div className={'fw-unde-cons-container'}>
                <div className={'fw-unde-cons-header'}>
                    <img src={suppComp.logo} alt={suppComp.name} />
                    <div className={'fw-unde-header-title'}>{suppComp.name}</div>
                </div>
                <div className={'fw-unde-cons-body'}>
                    <div className={'fw-unde-detail-wrapper'}>
                        <div className={'fw-unde-detail-title'}>페이지 제작중</div>
                        <div className={'fw-unde-detail-detail'}>
                            죄송합니다. 현재 이 페이지는 제작 중입니다. 곧 완성하여 찾아뵙겠습니다.
                        </div>
                    </div>
                    <figure className={'fw-unde-img-wrapper'}>
                        <img src={UnderImg} alt="페이지 제작 중" />
                    </figure>                          
                </div>          
            </div>
        </div>        
        </>
    )
}