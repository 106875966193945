// ChrgInfoChart - 대쉬보드 - 충전 정보(충전량, 충전시간, 매출액)
import React from 'react';
import { CommChart } from '../../../../script/components/charts/charts';
import { BarsSeri, CommAxis, SpinSeri } from '../../../../script/components/charts/charts-item';
import { Export } from 'devextreme-react/cjs/chart';

export default function ChrgInfoChart(props) {
    const {
        charData, charRef, height,
    } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${arg.valueText}`,
        };
    };

    return (
        <CommChart
            height={height}
            charRef={charRef}
            palette={"Soft Pastel"}
            chartsDataSource={charData}
            legend={{ vertical: "top", horizontal: "center" }}
            tooltip={{ location: "edge", template: tooltipTamplate }}
        >
            <Export 
                enabled
                fileName='충전기차트'
            />
            {CommAxis({ name: "ChargeKwh", caption: "충전량(kWh)", position: "left", start: 0 })}
            {CommAxis({ name: "ChargeTme", caption: "충전시간(초)", position: "left", start: 0 })}
            {CommAxis({ name: "ChargePay", caption: "매출액(원)", position: "right", start: 0 })}
            {CommAxis({ name: "ChargeCnt", caption: "사용횟수", position: "right", start: 0 })}
            {BarsSeri({ axis: "ChargeKwh", valueField: "char_powe", caption: "충전량(kWh)", args: "char_peri" })}
            {SpinSeri({ axis: "ChargeTme", valueField: "tran_dura", caption: "충전시간(초)", args: "char_peri" })}
            {SpinSeri({ axis: "ChargePay", valueField: "char_pric", caption: "매출액(원)", args: "char_peri" })}
            {SpinSeri({ axis: "ChargePay", valueField: "char_qtys", caption: "사용횟수", args: "char_peri" })}
        </CommChart>
    );
};