import AnalDailStat from "./dail/infoStat-page";
import AnalChrgStat from "./chrg/InfoDash-page";
import AnalAserStat from "./aser/InfoHist-page";

export const anal = ({ acce, code }) => {
    const cate = 'ana';

    const comp = {
        dailStat: <AnalDailStat acce={acce} cate={cate} code={`${code}1`} mainTitle={'일자별 충전량'} mainRole={'조회'} subsTitle={'상세'} subsRole={'현황'} />,
        chrgStat: <AnalChrgStat acce={acce} cate={cate} code={`${code}2`} mainTitle={'충전기 그래프'} mainRole={'조회'} />,
        aserStat: <AnalAserStat acce={acce} cate={cate} code={`${code}3`} mainTitle={'충전기 장애이력'} mainRole={'조회'} />,
    }

    return comp;
}