import { fwUtil } from "../../focuswin/script/util";
import routerInfo from "../../utils/router-info";

const getCompList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/sys/info/regist/clientele/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getCompList.error === ', error);
        return [];
    }
};

export {
    getCompList,
}