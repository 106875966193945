import { SelectBox } from "devextreme-react";
import Bullet, { Size, Tooltip } from "devextreme-react/bullet";
import { fwUtil } from "../../../util";

const defaultAlignment = "left";

const percentTooltip = (e) => {
  delete e.targetValue;
  e.text = `${e.originalValue}%`;
  return e;
};

const ColuPublOpts = (props) => {
  const {
    key,
    check,
    header,
    visible,
    fixed,
    dataField,
    caption,
    allowFiltering,
    allowSorting,
    allowEditing,
    allowResizing,
  } = props;

  const fixedOpt = fwUtil.conv.tern(false, fixed);
  const visiblieOpt = fwUtil.conv.tern(true, visible);
  const allowFilterOpt = fwUtil.conv.tern(true, allowFiltering);
  const allowSortingOpt = fwUtil.conv.tern(true, allowSorting);
  const allowEditingOpt = fwUtil.conv.tern(true, allowEditing);
  const allowResizingOpt = fwUtil.conv.tern(true, allowResizing);

  return {
    key: key,
    dataField: dataField,
    caption: caption,
    fixed: fixedOpt,
    visible: visiblieOpt,
    allowFiltering: allowFilterOpt,
    allowSorting: allowSortingOpt,
    allowEditing: allowEditingOpt,
    allowResizing: allowResizingOpt,
    cssClass: fwUtil.conv.class({
      "fw-check-column": check,
      "fw-header-column": header,
    }),
  };
};

const statCell = (cellInfo, warning, info, danger) => {
  const warningCheck = cellInfo.value === warning;
  const infoCheck = cellInfo.value === info;
  const dangerwarningCheck = cellInfo.value === danger;

  return (
    <div
      className={fwUtil.conv.class({
        "fw-warning-column": warningCheck,
        "fw-info-column": infoCheck,
        "fw-danger-column": dangerwarningCheck,
        "fw-stat-column": true,
      })}
    >
      {cellInfo.value}
    </div>
  );
};

const counCell = (cellInfo) => {
  return <span className="fw-grid-count">{cellInfo.row.rowIndex}</span>;
};

const selcCell = (cell, items, placeholder) => {
  const placeholderMessage = fwUtil.conv.tern(placeholder, "선택해주세요");
  const onValueChanged = (e) => cell.setValue(e.value);

  return (
    <SelectBox
      {...cell.column.lookup}
      defaultValue={cell.value}
      onValueChanged={onValueChanged}
      placeholder={placeholderMessage}
      items={items}
    />
  );
};

const barsCell = (cell, dataField) => {

  return (
    <Bullet
      showTarget={false}
      showZeroLevel={false}
      value={cell.data[`${dataField}`]}
      startScaleValue={0}
      endScaleValue={100}
    >
      <Tooltip customizeTooltip={gridUtil.tooltips.perc} />
      <Size width={"auto"} height={20} />
    </Bullet>
  );
};

const calcNumbCell = (data, dataField, suffix) => {
  let fielddata = data && data[dataField];
  return fwUtil.conv.check.nub(suffix) ? fielddata : fielddata + " " + suffix;
};

const calcDeciCell = (data, dataField, suffix, customCellValue) => {
  let fielddata = data && data[dataField];
  if (customCellValue) {
    fielddata = customCellValue(data);
  }
  let toNumber = "0.0";
  if (typeof fielddata === "number" && !isNaN(fielddata)) {
    toNumber = fielddata.toLocaleString();
  } else {
    let parsedFloat = parseFloat(fielddata);
    if (!isNaN(parsedFloat)) {
      toNumber = parsedFloat.toLocaleString();
    }
  }
  return fwUtil.conv.check.nub(suffix) ? toNumber : toNumber + " " + suffix;
};

const calcBoolCell = (data, dataField) => {
  if (data && data[dataField]) {
    if (data[dataField] === 1 || data[dataField] === true) {
      return true;
    }
  }
  return false;
};

export const gridUtil = {
  publOpts: ColuPublOpts,
  tooltips: {
    perc: percentTooltip,
  },
  defaOpts: {
    alig: defaultAlignment,
  },
  render: {
    stat: statCell,
    coun: counCell,
    selc: selcCell,
    bars: barsCell,
  },
  calculate: {
    numb: calcNumbCell,
    deci: calcDeciCell,
    bool: calcBoolCell,
  },
};
