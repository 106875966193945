import InfrAserMana from "./aser/InfoMana-page";

export const infr = ({ acce, code }) => {
    const cate = 'qci';

    const comp = {
        aserMana: <InfrAserMana acce={acce} cate={cate} code={`${code}1`} mainTitle={'A/S이력'} mainRole={'관리'} />,
    }

    return comp;
}