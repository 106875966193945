import { fwUtil } from './focuswin/script/util';
import { withNavigationWatcher } from './contexts/navigation';
import UndeConsPage from './focuswin/pages/public/UndeCons';
import routerInfo from './utils/router-info';
import * as fwCms from './focuswin/pages/cms/index';

let cmsRoutes = [];

async function fetchRoutes() {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post(`${routerInfo.publRout}/api/menu/routerList`);
        return {
            userMenu: response.data.userMenu,
        };
    } catch (error) {
        console.error("appRoutes.setting.error : " + error);
    }
};

export async function generateDynamicRoutes() {
    const menuList = await fetchRoutes();
    const cmsMenuList = menuList.userMenu;

    const cmsMap = cmsMenuList.map(menuitem => {
        let menuCode = menuitem.menu_code.trim();
        let menuHigh = menuitem.menu_high.trim();
        let menuName = menuitem.menu_name.trim();
        let menuPath = menuitem.menu_path.trim();
        let menuFile = menuitem.menu_file.trim();

        if (!fwUtil.conv.check.nue(menuPath) && !fwUtil.conv.check.nue(menuFile)) {
            return {
                mkey: menuCode,
                skey: menuHigh,
                name: menuName,
                path: menuPath,
                element: fwCms[menuFile] || UndeConsPage
            };
        } else {
            return null;
        }
    }).filter(route => route !== null);   

    cmsRoutes = cmsMap.map(route => ({
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    }));

    return {
        cmsRoutes
    }
};