import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigation } from './contexts/navigation';
import { intrComp, suppComp } from './utils/default-comp';
import { Footer, SideNavToolbar as SideNavBarLayout } from './focuswin/script/components';

export default function CmsContent() {
  const { cmsRouteList, routeLoading } = useNavigation();

  if (routeLoading) {
    return;
  }

  return (
      <SideNavBarLayout intrComp={intrComp} suppComp={suppComp} menu={"cms"} >
        <Routes>
          {cmsRouteList.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={element}
            />
          ))}
          <Route
            path='*'
            element={<Navigate to='/moni/dash/stat' />}
          />
        </Routes>
        <Footer>
          EvCMS Ver1.0 - Copyright © 2024-{new Date().getFullYear()} {intrComp.name} Inc.
          <br />
          All trademarks or registered trademarks are property of their
          respective owners.          
        </Footer>
      </SideNavBarLayout>
  );
}

