import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    bizs_gubu: '', 
    main_code: '', 
    main_yesn: true, 
    main_name: '', 
    main_memo: '',
};

export function CodeMainManaUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
        const selectedRowData = fwUtil.grid.get.sData(mainGrid);
        if (!selectedRowData) {
            hiding();
        } else {
            const prevData = {
                ...defaultData,
                main_gubu: selectedRowData.main_gubu || '',
                main_code: selectedRowData.main_code || '',
                main_yesn: selectedRowData.main_yesn || false,
                main_name: selectedRowData.main_name || '',
                main_memo: selectedRowData.main_memo || '',
            };
            dataUpdate(prevData);
        }
    }, [mainGrid, hiding, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainCode: data.main_code || '',
            mainYesn: data.main_yesn || false,
            mainName: data.main_name || '',
            mainMemo: data.main_memo || '',
        };

        await fwUtil.apis.submit(updateApi, postData , refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
        <PopupForm
            title={'공통코드 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 340 : 600 }  
        >
            <GroupItem>
                {FormText({ value: data.main_gubu, onValueChange: updateField('main_gubu'), label: '업무분류', required: true, disabled: true  })}
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '관리코드명', required: true })}
                {FormBool({ value: data.main_yesn, onValueChange: updateField('main_yesn'), label: '사용여부' })}
                {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
            </GroupItem>
        </PopupForm>
        </>
    );
}