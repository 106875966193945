import React, { useEffect, useState, memo } from 'react';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { DateCol, DeciCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const MoniDashRptsGrid = memo((props) => {
    const {
        gridData, gridRef, rptsCharHeight,
        mainTitle, mainRole, additonTitle
    } = props;
    
    // 그리드
    const mainKey = 'date_time';
    const [gridHeight, setGridHeight] = useState(0);    

    useEffect(() => {
        const pageContentElement = document.getElementById('fw-page-content');
        if (pageContentElement) {
            const pageContentHeight = pageContentElement.clientHeight - 20;
            setGridHeight(pageContentHeight - rptsCharHeight);
        }
    }, [rptsCharHeight]);    

    return (
        <ScrollGrid
            title={mainTitle}
            role={mainRole}
            additonTitle={additonTitle}
            mainKey={mainKey}
            className={'monitor-reports-grid'}
            height={gridHeight}
            gridRef={gridRef}
            gridDataSource={gridData}
            loadPanel={false}
            toolbar={{ titleOnly: true }}
            selection={'none'}
        >
            {DateCol({ dataField: "date_time", caption: "일자", width: 200 })}
            {DeciCol({ dataField: "usePower", caption: "충전량", suffix: "kWh", width: 190 })}
            {NumbCol({ dataField: "usageCount", caption: "충전 횟수", width: 150 })}
            {DeciCol({ dataField: "resultPrice", caption: "매출액", suffix: "원" })}
        </ScrollGrid>
    );
});

export default MoniDashRptsGrid;
