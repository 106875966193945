import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupAddr, PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormGrid, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { getCompList } from '../../../../../api/cms/set-user';

const defaultData = {
    id: '',
    pwd: '',
    user_name: '',
    mobile_no: '',
    car_no: '',
    company_code: '',
    enab_yesn: false,
    memb_okay: false,
    mail_okay: false,
    mail_idno: '',
    user_chek: '',
    zips_code: '',
    addr_knam: '',
};

function convertUserChek(userChek) {
    const userChekMap = {
        "시스템관리자": 0,
        "충전소관리자": 1,
        "사용자": 2,
    };

    return userChekMap[userChek] !== undefined ? userChekMap[userChek] : 2;
}

function revertUserChek(userChek) {
    const userChekMap = {
        0: "시스템관리자",
        1: "충전소관리자",
        2: "사용자",
    };

    return userChekMap[userChek] !== undefined ? userChekMap[userChek] : "사용자";
}


const userLeveList = ["시스템관리자", "충전소관리자", "사용자"];

export function UserAccoUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 주소 팝업
    const [addrPopupVisible, setAddrPopupVisible] = useState(false);
    const addrPopupHide = useCallback(() => setAddrPopupVisible(false), []);
    const addrPopupConfirm = useCallback(async (postData) => {
        const updateedData = {
            ...data,
            zips_code: postData.zonecode,
            addr_knam: postData.address,
        };
        addrPopupHide();
        dataUpdate(updateedData);
    }, [data, addrPopupHide, dataUpdate]);
    const addrButton = {
        name: 'addr-search-btn',
        loca: 'after',
        text: '주소찾기',
        mode: 'outlined',
        class: 'fw-addr-search-btn',
        click: () => setAddrPopupVisible(true),
    };    
    // 팝업 그리드
    const compGridRef = useRef(null);
    const [compData, setCompData] = useState([]);
    const compGridUpdate = useCallback((e) => setCompData(e), []);
    const compKeyExpr = 'clie_code';
    // 그리드 팝업
    const [compPopupVisible, setCompPopupVisible] = useState(false);
    const compPopupHiding = useCallback(() => setCompPopupVisible(false), []);

    const compHandleSearch = useCallback(async () => {
        const sendData = { compNumb: "", compName: "" };
        const res = await getCompList(sendData);
        compGridUpdate(res);
    }, [compGridUpdate]);    

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
    
            if (seleData) {
                const targData = {
                    id: seleData.id || '',
                    pwd: seleData.pwd || '',
                    user_name: seleData.user_name || '',
                    mobile_no: seleData.mobile_no || '',
                    car_no: seleData.car_no || '',
                    company_code: seleData.company_code || '',
                    enab_yesn: seleData.enab_yesn || false,
                    memb_okay: seleData.memb_okay || false,
                    mail_okay: seleData.mail_okay || false,
                    mail_idno: seleData.mail_idno || '',
                    user_chek: revertUserChek(seleData.user_chek),
                    zips_code: seleData.zips_code || '',
                    addr_knam: seleData.addr_knam || '',                  
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            id: data.id || '',
            pwd: data.pwd || '',
            userName: data.user_name || '',
            mobileNo: data.mobile_no || '',
            carNo: data.car_no || '',
            companyCode: data.company_code || '',
            enabYesn: data.enab_yesn ? 1 : 0,
            membOkay: data.memb_okay ? 1 : 0,
            mailOkay: data.mail_okay ? 1 : 0,
            mailIdno: data.mail_idno || '',
            userChek: convertUserChek(data.user_chek),
            zipsCode: data.zips_code || '',
            addrKnam: data.addr_knam || '',
        };

        await fwUtil.apis.check(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    const compPopupOnShowing = useCallback(() => {
        compHandleSearch();
    }, [compHandleSearch]);

    const compOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'company_code': e.data.clie_code, 'user_name': e.data.pres_name, 'mail_idno': e.data.mail_idno, 'mobile_no': e.data.tele_numb, 'zips_code': e.data.zips_code, 'addr_knam': e.data.addr_knam });
        compPopupHiding();
    }, [data, dataUpdate, compPopupHiding]);

    const compGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'사업자'}
                role={'목록'}
                gridDataSource={compData}
                gridRef={compGridRef}
                mainKey={compKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={compOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명" })}
                {CommCol({ dataField: "mail_idno", caption: "이메일" })}
                {CommCol({ dataField: "tele_numb", caption: "연락처" })}
                {CommCol({ dataField: "zips_code", caption: "우편번호" })}
                {CommCol({ dataField: "addr_knam", caption: "주소" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}                 
            </ScrollGrid>
        )
    }, [compData, compGridRef, compKeyExpr, compOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'회사코드 설정'}
                visible={compPopupVisible}
                shown={compPopupOnShowing}
                hiding={compPopupHiding}
            >
                {compGrid}
            </PopupGrid>        
            <PopupForm
                title={'사용자 계정 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 800 }
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                    {FormSelc({ value: data.user_chek, onValueChange: updateField('user_chek'), label: '사용자레벨', items: userLeveList, required: true })}
                    {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                    {FormText({ value: data.id, onValueChange: updateField('id'), label: '사용자ID', required: true, disabled: true })}
                    {FormText({ value: data.pwd, onValueChange: updateField('pwd'), label: '비밀번호' })}
                    {FormText({ value: data.user_name, onValueChange: updateField('user_name'), label: '사용자명', required: true })}
                    {FormText({ value: data.mobile_no, onValueChange: updateField('mobile_no'), label: '연락처' })}
                    {FormGrid({ value: data.company_code, onValueChange: updateField('company_code'), label: '회사코드', onClick: () => setCompPopupVisible(true) })}
                    {FormBool({ value: data.memb_okay, onValueChange: updateField('memb_okay'), label: '약관동의' })}
                    {FormText({ value: data.mail_idno, onValueChange: updateField('mail_idno'), label: '메일' })}                    
                    {FormBool({ value: data.mail_okay, onValueChange: updateField('mail_okay'), label: '메일인증' })}
                    {FormText({ value: data.zips_code, onValueChange: updateField('zips_code'), label: '우편번호', button: addrButton, maxLength: 5 })}
                    {FormText({ value: data.car_no, onValueChange: updateField('car_no'), label: '차량번호' })}
                </GroupItem>
                {FormText({ value: data.addr_knam, onValueChange: updateField('addr_knam'), label: "주소" })}
            </PopupForm>
            <PopupAddr
                visible={addrPopupVisible}
                hiding={addrPopupHide}
                confirm={addrPopupConfirm}
            />
        </>
    );
}