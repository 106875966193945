import { LoadPanel } from "devextreme-react";
import "./load-overlay.scss"

function LoadOverlay(props) {
    const { visible } = props;

    return (
        <LoadPanel 
            visible={visible}
            shading
            className="fw-load-panel"
            shadingColor="rgba(0, 0, 0, .4)"
        />
    )
};

export {
    LoadOverlay
};