import { fwUtil } from '../../util';
import { contents } from './map-func/map-contents';

const { kakao } = window;

let geocoder;

if (kakao) {
    geocoder = new kakao.maps.services.Geocoder();
}

let stationContent = [];
let chargerContent = [];

async function initMarker (map, geocoder, data, mode) {
    if (!data) {
        return;
    }
    
    if (mode === 'station') {
        for (const [index, item] of data.entries()) {
            await setStationMarker(map, geocoder, item, stationContent, index === 0);
        }
    }
    if (mode === 'charger') {
        await delMarker(chargerContent);
        /* eslint-disable */
        for (const [index, item] of data.entries()) {
            await setChargerMarker(map, item, chargerContent);
        }
        /* eslint-disable */
    }
};

function delMarker(arr) {
    return new Promise((resolve, reject) => {
        if (!fwUtil.conv.check.nua(arr)) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].setMap(null);
            }
            resolve();
        } else {
            resolve();
        }
    });
}

function setStationMarker(map, geocoder, data, dataArr, isInit) {
    if (map && geocoder && data) {
        if (data.station_address) {
            return new Promise((resolve, reject) => {
                geocoder.addressSearch(data.station_address, function(result, status) {
                    if (status === kakao.maps.services.Status.OK) {
                        const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

                        const isDuplicate = dataArr.some(dataArr => {
                            const markerPosition = dataArr.getPosition();
                            return markerPosition.getLat() === coords.getLat() && markerPosition.getLng() === coords.getLng();
                        });

                        if (!isDuplicate) {
                            const content = contents.marker.station(data);
                            const stationContent = new kakao.maps.CustomOverlay({
                                content: content,
                                map: map,
                                position: coords,
                                yAnchor: 1,
                                zIndex: 10,
                            });

                            stationContent.setMap(map);
                            isInit && map.setCenter(coords);
                            dataArr.push(stationContent);
                        }

                        resolve();
                    } else {
                        reject(new Error('Address search failed'));
                    }
                });
            });
        }
    }
    return Promise.resolve();
}

function setChargerMarker(map, data, dataArr) { 
    const coords = new kakao.maps.LatLng(data.gps_ypos, data.gps_xpos);

    const isDuplicate = dataArr.some(marker => {
        const markerPosition = marker.getPosition();
        return markerPosition.getLat() === coords.getLat() && markerPosition.getLng() === coords.getLng();
    });

    if (!isDuplicate) {
        const content = contents.marker.charger(data);
    
        const chargerContent = new kakao.maps.CustomOverlay({
            content: content,
            map: map,
            position: coords,
            yAnchor: 0.5,
            zIndex: 15,
        });            
    
        chargerContent.setMap(map);

        kakao.maps.event.addListener(map, 'click', function() {
            const allStMarker = document.querySelectorAll('.fw-map-overlay-content');
            allStMarker.forEach(marker => {
                marker.classList.remove('sel');
            });        
        });
    
        dataArr.push(chargerContent);
    }
}

async function getGeoCoords(addr) {
    return new Promise((resolve, reject) => {
        geocoder.addressSearch(addr, function(result, status) {
            if (status === kakao.maps.services.Status.OK && (result[0].x && result[0].y)) {
                resolve([result[0].x, result[0].y]);
            } else {
                fwUtil.aler.toast.erro('좌표변환에 실패했습니다.');
                reject('좌표변환에 실패했습니다.');
            }
        });
    });
}

function isValidCoordinates(x, y) {
    const numX = parseFloat(x);
    const numY = parseFloat(y);

    const minLatitude = -90;
    const maxLatitude = 90;
    const minLongitude = -180;
    const maxLongitude = 180;

    return (
        !isNaN(numX) && !isNaN(numY) &&
        numX >= minLongitude && numX <= maxLongitude &&
        numY >= minLatitude && numY <= maxLatitude
    );
}

function navigateByAddress(map, address, callback) {
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(address, function(result, status) {
        if (status === kakao.maps.services.Status.OK) {
            const revertX = (parseFloat(result[0].x) - 0.002).toString();
            const coords = new kakao.maps.LatLng(result[0].y, revertX);
            map.setLevel(3);            
            map.setCenter(coords);
            callback && callback();
        }
    })
};

function navigateByPosition(map, x, y) {
    return new Promise((resolve, reject) => {
        if (isValidCoordinates(x, y)) {
            const revertX = (parseFloat(x) - 0.0004).toString();
            const coords = new kakao.maps.LatLng(y, revertX);
            map.setLevel(1);            
            map.setCenter(coords); 
            resolve();
        } else {
            reject(new Error('Invalid coordinates'));
        }
    });
}

function viewSetting(map, view) {
    if (!map || !view) {
        return;
    }

    const viewCtrl = view.controler;
    const viewCtrlPosi = fwUtil.conv.tern('TOPLEFT', viewCtrl.position);
    if (viewCtrl.able) {
        const mapTypeControl = new kakao.maps.MapTypeControl();
        map.addControl(mapTypeControl, kakao.maps.ControlPosition[`${viewCtrlPosi}`]);
    }
};

function zoomSetting(map, zoom) {
    if (!map || !zoom) {
        return;
    }

    const zoomCtrl = zoom.controler;
    const zoomMaxs = zoom.max;
    const zoomMins = zoom.min;
    const zoomCtrlPosi = fwUtil.conv.tern('LEFT', zoomCtrl.position);
    if (zoomCtrl.able) {
        const zoomControl = new kakao.maps.ZoomControl();
        map.addControl(zoomControl, kakao.maps.ControlPosition[`${zoomCtrlPosi}`]);
    }
    zoomMaxs && map.setMaxLevel(zoomMaxs);
    zoomMins && map.setMaxLevel(zoomMins);
};

const mapUtil = {
    set: {
        view: (map, view) => viewSetting(map, view),        
        zoom: (map, zoom) => zoomSetting(map, zoom),
        marker: {
            station: (map, geocoder, data) => initMarker(map, geocoder, data, 'station'),
            charger: (map, geocoder, data) => initMarker(map, geocoder, data, 'charger'),
        }
    },
    get: {
        posi: getGeoCoords,
    },
    navigate: {
        addr: navigateByAddress,
        posi: navigateByPosition,
    }
}

export default mapUtil;


