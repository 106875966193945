// ChrgBusiMana - 충전 관리 - 사업자 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { ChrgBusiInsert } from './InfoMana-insert';
import { ChrgBusiUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

const enabStatList = ['사용중', '미사용'];

export default function ChrgBusiMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const infoMiddleApi = 'clie';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole? " " + mainRole : ""}`;    
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'clie_code';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);  
    // 검색 조건 
    const [enabYesn, setEnabYesn] = useState("사용중");
    const [clieName, setClieName] = useState("");
    const [clieSano, setClieSano] = useState("");
    const [clieNick, setClieNick] = useState("");
    const enabYesnChange = useCallback((e) => { setEnabYesn(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const clieSanoChange = useCallback((e) => { setClieSano(e) }, []);
    const clieNickChange = useCallback((e) => { setClieNick(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { enabYesn: enabYesn === '사용중' ? 1 : 0, clieName: clieName, clieSano: clieSano, clieNick: clieNick  };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef);
    }, [enabYesn, clieName, clieSano, clieNick, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '사용여부', value: enabYesn, valueChange: enabYesnChange, search: handleSearch, items: enabStatList },
        { type: 'text', locate: 'before', caption: '사업자명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '사업자번호', value: clieSano, valueChange: clieSanoChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: 'VendorID', value: clieNick, valueChange: clieNickChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [enabYesn, enabYesnChange, clieName, clieNameChange, clieSano, clieSanoChange, clieNick, clieNickChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
        <ContentHeader icon={'fw-chrg'} title={title} />  
        <div id={'fw-page-content'}>
            <PagingGrid
                title={'사업자 현황'}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >            
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "damd_name", caption: "담당자명" })}
                {CommCol({ dataField: "dept_name", caption: "담당자 부서" })}
                {CommCol({ dataField: "posi_name", caption: "담당자 직위" })}
                {CommCol({ dataField: "mail_idno", caption: "담당자 이메일" })}
                {CommCol({ dataField: "damd_teln", caption: "담당자 전화" })}
                {CommCol({ dataField: "damd_faxn", caption: "담당자 팩스" })}
                {CommCol({ dataField: "damd_cell", caption: "담당자 H.P" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명" })}
                {CommCol({ dataField: "upta_name", caption: "업태" })}
                {CommCol({ dataField: "jong_moks", caption: "종목" })}
                {CommCol({ dataField: "tele_numb", caption: "전화번호" })}
                {CommCol({ dataField: "faxs_numb", caption: "팩스번호" })}
                {CommCol({ dataField: "zips_code", caption: "우편번호" })}
                {CommCol({ dataField: "addr_knam", caption: "주소" })}
                {CommCol({ dataField: "clie_nick", caption: "사업자 별칭" })}
                {CommCol({ dataField: "envi_code", caption: "환경부 기관코드" })}
                {CommCol({ dataField: "envi_keys", caption: "환경부 인증키" })}
                {CommCol({ dataField: "clie_memo", caption: "비고" })}
                {CommCol({ dataField: "user_name", caption: "작성자" })}
                {DateCol({ dataField: "user_date", caption: "작성일" })}
                {CommCol({ dataField: "modi_name", caption: "수정자" })}
                {DateCol({ dataField: "modi_date", caption: "수정일" })}
            </PagingGrid>
            <ChrgBusiInsert
                refresh={handleSearch}
                insertApi={infoInsertApi}
                visible={infoInsertPopupVisible}
                hiding={infoInsertPopupHide}
            />
            <ChrgBusiUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                updateApi={infoUpdateApi}
                visible={infoUpdatePopupVisible}
                hiding={infoUpdatePopupHide}
            />                            
        </div>        
        </>
    );
};