import CommCodeMana from "./code/InfoMana-page";


export const comm = ({ acce, code }) => {
    const cate = 'com';

    const comp = {
        codeMana: <CommCodeMana acce={acce} cate={cate} code={`${code}1`} mainTitle={'공통코드'} mainRole={'관리'} />,
    }

    return comp;
}