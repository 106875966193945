import UserAccoMana from "./acco/InfoMana-page";
import UserRfidMana from "./rfid/CardMana-page";
import UserCredMana from "./cred/CardMana-page";

export const user = ({ acce, code }) => {
    const cateSys = 'sys';
    const cateCom = 'com';

    const comp = {
        accoMana: <UserAccoMana acce={acce} cate={cateSys} code={`${code}1`} mainTitle={'계정'} mainRole={'관리'} />,
        rfidMana: <UserRfidMana acce={acce} cate={cateCom} code={`${code}2`} mainTitle={'RFID카드'} mainRole={'관리'} />,
        credMana: <UserCredMana acce={acce} cate={cateCom} code={`${code}3`} mainTitle={'결제카드'} mainRole={'관리'} />,
    }

    return comp;
}