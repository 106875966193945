export async function convertChartData(data, date, gubu) {
    const result = [];

    function getLastDayOfMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    if (!data || !date || !gubu) {
        return;
    }

    if (gubu === '년') {
        const year = date;

        for (let month = 1; month <= 12; month++) {
            const period = `${year}-${month.toString().padStart(2, '0')}`;
            result.push({
                char_peri: period,
                char_pric: 0,
                char_powe: 0,
                char_qtys: 0,
                tran_dura: 0
            });
        }

        data.forEach(item => {
            if (item.char_peri) {
                const index = result.findIndex(entry => entry.char_peri === item.char_peri);
                if (index !== -1) {
                    result[index].char_pric += item.char_pric || 0;
                    result[index].char_powe += item.char_powe || 0;
                    result[index].char_qtys += 1;

                    
                    const tranDuraValue = typeof item.tran_dura === 'string' ? parseFloat(item.tran_dura) : item.tran_dura;
                    result[index].tran_dura += isNaN(tranDuraValue) ? 0 : tranDuraValue;
                }
            }
        });

    } else if (gubu === '월') {
        const [year, month] = date.split('-');
        const lastDay = getLastDayOfMonth(year, month);

        for (let day = 1; day <= lastDay; day++) {
            const period = `${day.toString().padStart(2, '0')}`;
            result.push({
                char_peri: period,
                char_pric: 0,
                char_powe: 0,
                char_qtys: 0,
                tran_dura: 0
            });
        }

        data.forEach(item => {
            if (typeof item.char_peri === 'string' && item.char_peri.length >= 2) {
                const charPeriPrefix = item.char_peri.slice(-2);

                const index = result.findIndex(entry =>
                    entry.char_peri === charPeriPrefix
                );

                if (index !== -1) {
                    result[index].char_pric += item.char_pric || 0;
                    result[index].char_powe += item.char_powe || 0;
                    result[index].char_qtys += 1;

                    
                    const tranDuraValue = typeof item.tran_dura === 'string' ? parseFloat(item.tran_dura) : item.tran_dura;
                    result[index].tran_dura += isNaN(tranDuraValue) ? 0 : tranDuraValue;
                }
            }
        });

    } else if (gubu === '일') {
        const endDate = new Date(date);
        endDate.setDate(endDate.getDate() + 1);

        for (let hour = 0; hour < 24; hour++) {
            const period = `${hour.toString().padStart(2, '0')}:00`;
            result.push({
                char_peri: period,
                char_pric: 0,
                char_powe: 0,
                char_qtys: 0,
                tran_dura: 0
            });
        }

        data.forEach(item => {
            if (typeof item.char_peri === 'string' && item.char_peri.length >= 2) {
                const charPeriPrefix = item.char_peri.slice(-2) + ":00";

                const index = result.findIndex(entry => entry.char_peri === charPeriPrefix);
                if (index !== -1) {
                    result[index].char_pric += item.char_pric || 0;
                    result[index].char_powe += item.char_powe || 0;
                    result[index].char_qtys += 1;

                    
                    const tranDuraValue = typeof item.tran_dura === 'string' ? parseFloat(item.tran_dura) : item.tran_dura;
                    result[index].tran_dura += isNaN(tranDuraValue) ? 0 : tranDuraValue;
                }
            }
        });
    }

    return result;
}
