import { ArgumentAxis, Series, Title, ValueAxis, VisualRange } from "devextreme-react/cjs/chart";
import { fwUtil } from "../../util";

const CommAxis = (props) => {
    const {
        name, caption, position, tick, showZero, 
        start, end, margin, maxMargin
    } = props;

    const axisMarginEnabled = fwUtil.conv.tern(true, margin);
    const valueMaxMargin = fwUtil.conv.tern(0.1, maxMargin)

    return (
        <ValueAxis
            name={name}
            position={position}
            tickInterval={tick}
            showZero={showZero}
            valueMarginsEnabled={axisMarginEnabled}
            maxValueMargin={valueMaxMargin}
        >
            <Title text={caption} />
            <VisualRange
                startValue={start}
                endValue={end}
            />
        </ValueAxis>
    )
};

const CommArgs = (props) => {
    const {
        name, margin
    } = props;

    const axisMarginEnabled = fwUtil.conv.tern(true, margin);

    return (
        <ArgumentAxis
            name={name}
            valueMarginsEnabled={axisMarginEnabled}
        />
    )
};

const BarsSeri = (props) => {
    const {
        valueField, caption, color, axis, args, group
    } = props;

    return (
        <Series
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}
            type="bar"
            color={color}
            barOverlapGroup={group}
        />
    )
};

const SpinSeri = (props) => {
    const {
        valueField, caption, color, axis, args
    } = props;

    return (
        <Series
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}            
            type="line"
            color={color}
        />
    )
};

const AreaSeri = (props) => {
    const {
        valueField, caption, color, axis, args
    } = props;

    return (
        <Series
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}            
            type="splinearea"
            color={color}
        />
    )
};

export { 
    CommAxis,
    CommArgs,
    BarsSeri,
    SpinSeri,
    AreaSeri
};