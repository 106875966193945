import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { getCommCodeList } from '../../../../../api/cms/set-comm';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    bizs_gubu: '', 
    main_code: '', 
    main_yesn: true, 
    main_name: '', 
    main_memo: '',
};

export function CodeMainManaInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 선택 항목
    const [gubuList, setGubuList] = useState([]);   
    const gubuListChange = useCallback((e) => setGubuList(e), []);    

    const fetchSet = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([getCommCodeList()]);
            gubuListChange(gubuResult);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro();
        }
    }, [gubuListChange]);

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchSet();
        dataUpdate(defaultData);
    }, [fetchSet, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            bizsGubu: data.bizs_gubu ? data.bizs_gubu.charAt(0) : '',
            mainName: data.main_name || '',
            mainYesn: data.main_yesn || false,
            mainMemo: data.main_memo || '',
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupForm
            title={'공통코드 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 340 : 600 }  
        >
            <GroupItem>
                {FormSelc({ value: data.bizs_gubu, onValueChange: updateField('bizs_gubu'), label: '업무분류', items: gubuList, required: true })}
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '관리코드명', required: true })}
                {FormBool({ value: data.main_yesn, onValueChange: updateField('main_yesn'), label: '사용여부' })}
                {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
            </GroupItem>
        </PopupForm>
        </>
    );
}