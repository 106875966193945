import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    aser_numb: '',
    aser_gubu: '접수',
    stin_idno: '',
    stin_name: '',
    cpin_idno: '',
    cpin_modl: '',
    clie_name: '',
    clie_teln: '',
    acce_date: fwUtil.date.getsText.date(),
    acce_name: '',
    acce_memo: '',
    comp_name: '',
    comp_date: fwUtil.date.getsText.date(),
    comp_memo: '',
    aser_memo: '',
};

const gubuItem = ['접수', '안내', '예정', '진행중', '완료'];

export function InfrAserUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
    
            if (seleData) {
                const targData = {
                    aser_numb: seleData.aser_numb || '',
                    aser_gubu: seleData.aser_gubu || '접수',
                    stin_idno: seleData.stin_idno || '',
                    stin_name: seleData.stin_name || '',
                    cpin_idno: seleData.cpin_idno || '',
                    cpin_modl: seleData.cpin_modl || '',
                    clie_name: seleData.clie_name || '',
                    clie_teln: seleData.clie_teln || '',
                    acce_date: seleData.acce_date || fwUtil.date.getsText.date(),
                    acce_name: seleData.acce_name || '',
                    acce_memo: seleData.acce_memo || '',
                    comp_name: seleData.comp_name || '',
                    comp_date: seleData.comp_date || fwUtil.date.getsText.date(),
                    comp_memo: seleData.comp_memo || '',
                    aser_memo: seleData.aser_memo || '',
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);
    
    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            aserNumb: data.aser_numb || '',
            aserGubu: data.aser_gubu || '',
            stinIdno: data.stin_idno || '',
            stinName: data.stin_name || '',
            cpinIdno: data.cpin_idno || '',
            cpinModl: data.cpin_modl || '',
            clieName: data.clie_name || '',
            clieTeln: data.clie_teln || '',
            acceDate: data.acce_date || fwUtil.date.getsText(),
            acceName: data.acce_name || '',
            acceMemo: data.acce_memo || '',
            compName: data.comp_name || '',
            compDate: data.comp_date || fwUtil.date.getsText(),
            compMemo: data.comp_memo || '',
            aserMemo: data.aser_memo || '',
        };

        await fwUtil.apis.submit(updateApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'A/S이력 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 800}
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                    {FormText({ value: data.aser_numb, onValueChange: updateField('aser_numb'), label: "A/S접수번호", disabled: true })}
                    {FormSelc({ value: data.aser_gubu, onValueChange: updateField('aser_gubu'), label: "진행상황", items: gubuItem })}
                    {FormText({ value: data.stin_idno, onValueChange: updateField('stin_idno'), label: "충전소ID", required: true })}
                    {FormText({ value: data.stin_name, onValueChange: updateField('stin_name'), label: "충전소명", required: true })}
                    {FormText({ value: data.cpin_idno, onValueChange: updateField('cpin_idno'), label: "충전기ID", required: true })}
                    {FormText({ value: data.cpin_modl, onValueChange: updateField('cpin_modl'), label: "충전기모델명", required: true })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: "신청고객명" })}
                    {FormText({ value: data.clie_teln, onValueChange: updateField('clie_teln'), label: "신청고객연락처" })}
                    {FormText({ value: data.acce_name, onValueChange: updateField('acce_name'), label: "접수자" })}
                    {FormDate({ value: data.acce_date, onValueChange: updateField('acce_date'), label: "접수일" })}
                    {FormText({ value: data.comp_name, onValueChange: updateField('comp_name'), label: "담당자" })}
                    {FormDate({ value: data.comp_date, onValueChange: updateField('comp_date'), label: "완료일" })}
                </GroupItem>
                {FormMemo({ value: data.acce_memo, onValueChange: updateField('acce_memo'), label: "접수증상" })}
                {FormMemo({ value: data.comp_memo, onValueChange: updateField('comp_memo'), label: "조치내용" })}
                {FormMemo({ value: data.aser_memo, onValueChange: updateField('aser_memo'), label: "특이사항" })}
            </PopupForm>
        </>
    );
}