// AnalAserStat - 정산/지표 분석 - 충전기 장애이력 조회
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { fwUtil } from '../../../../script/util';

function formatConnectorId(data) {
    if (!data || isNaN(data.connector_id)) {
        return '--';
    }
    
    const connectorId = data.connector_id;
    return connectorId.toString().padStart(2, '0');
}

const chrgStateCell = (cellInfo) => {
    const value = cellInfo.value;
    const text = fwUtil.conv.to.krChrgStat(value);
    const color = fwUtil.conv.to.chrgColors(value)

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {text ? text : '-'}</span>
        </div>
    )
};

export default function AnalAserStat(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'error/history';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'stat_keys';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건    
    const [stationName, setStationName] = useState("");
    const [chargerId, setChargerId] = useState("");
    const stationNameChange = useCallback((e) => { setStationName(e) }, []);
    const chargerIdChange = useCallback((e) => { setChargerId(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { stationName: stationName, chargerId: chargerId };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [stationName, chargerId, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '충전소명', value: stationName, valueChange: stationNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '충진기ID', value: chargerId, valueChange: chargerIdChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [stationName, stationNameChange, chargerId, chargerIdChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'충전기 장애이력 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "station_name", caption: "충전소명" })}
                    {DateCol({ dataField: "last_upt_date", caption: "최종 업데이트 날짜", width: 160 })}
                    {CommCol({ dataField: "id", caption: "충전기 ID" })}
                    {CommCol({ dataField: "connector_id", caption: "커넥터 ID", width: 120, displayExpr: (e) => formatConnectorId(e) })}
                    {CommCol({ dataField: "errorCode", caption: "장애원인" })}
                    {StatCol({ dataField: "state", caption: "현재상태", customCell: chrgStateCell })}
                    {CommCol({ dataField: "pres_erro", caption: "현재 장애상태" })}
                </PagingGrid>
            </div>
        </>
    );
};