
import React from 'react';
import { Toolbar as NToolbar } from 'devextreme-react';
import { Toolbar as GToolbar, Item as GItem } from "devextreme-react/data-grid"
import { Item as NItem } from 'devextreme-react/cjs/toolbar';
import { ToolbarButton, ToolbarExportBtn, ToolbarItem, ToolbarSearchBtn } from './toolbar-items';
import { ToolbarDateRangeItem } from './toolbar-default';
import { ToolbarHeader } from './toolbar-header';
import './toolbar.scss';

export function GridToolbar(props) {
    const {
        title,
        dateValue, dateValueChange, duraYesn, duraYesnChange,
        ins, upd, del, exp,
        defaultItems, toolbar
    } = props;

    return (
        <GToolbar>
            <GItem location='before'>
                <div className='fw-grid-header'>{title}</div>
            </GItem>
            {defaultItems &&
                <GItem location='before' locateInMenu='auto' cssClass='fw-grid-toolbar-calendar-item'>
                    <ToolbarDateRangeItem
                        duraYesn={duraYesn}
                        dateValue={dateValue}
                        duraYesnChange={duraYesnChange}
                        dateValueChange={dateValueChange}
                    />
                </GItem>
            }
            {toolbar && toolbar.items && toolbar.items.map((item, index) => (
                <GItem key={index} location={item.locate} locateInMenu='auto' cssClass={`fw-grid-toolbar-${item.type}-item`}>
                    <ToolbarItem
                        type={item.type}
                        width={item.width}
                        caption={item.caption}
                        value={item.value}
                        valueChange={item.valueChange}
                        items={item.items}
                        search={item.search}
                        icon={item.icon}
                        text={item.text}
                        hidden={item.hidden}
                        disabled={item.disabled}
                        onClick={item.onClick}
                        displayExpr={item.displayExpr}
                    />
                </GItem>
            ))}
            {ins &&
                <GItem location='after' locateInMenu='auto'>
                    <ToolbarButton
                        icon='plus'
                        text='등록'
                        visible={!ins.hidden}
                        disabled={ins.disabled}
                        onClick={ins.onClick}
                    />
                </GItem>
            }
            {upd &&
                <GItem location='after' locateInMenu='auto'>
                    <ToolbarButton
                        icon='edit'
                        text='수정'
                        visible={!upd.hidden}
                        disabled={upd.disabled}
                        onClick={upd.onClick}
                    />
                </GItem>
            }
            {del &&
                <GItem location='after' locateInMenu='auto'>
                    <ToolbarButton
                        icon='trash'
                        text='삭제'
                        visible={!del.hidden}
                        disabled={del.disabled}
                        onClick={del.onClick}
                    />
                </GItem>
            }
            <GItem name='exportButton' disabled={exp && exp.disabled} />
            <GItem name='columnChooserButton' locateInMenu='auto' />
            <GItem name='searchPanel' locateInMenu='auto' />
        </GToolbar>
    )
};

const DashToolbar = React.memo(function DashToolbar(props) {
    const {
        title, exp,
        dateValue, dateValueChange, duraYesn, duraYesnChange,
        search, toolbar,
    } = props;

    if (!toolbar) {
        return;
    }
    
    return (
        <NToolbar>
            <NItem location='before'>
                <ToolbarHeader title={title} type={'dash'} />
            </NItem>
            <NItem location='before' locateInMenu='auto' cssClass='fw-dash-toolbar-calendar-item'>
                <ToolbarDateRangeItem
                    duraYesn={duraYesn}
                    dateValue={dateValue}
                    duraYesnChange={duraYesnChange}
                    dateValueChange={dateValueChange}
                />
            </NItem>
            {toolbar && toolbar.items && toolbar.items.map((item, index) => (
                <NItem key={index} location={item.locate} locateInMenu='auto' cssClass={`fw-dash-toolbar-${item.type}-item`}>
                    <ToolbarItem
                        type={item.type}
                        caption={item.caption}
                        value={item.value}
                        valueChange={item.valueChange}
                        items={item.items}
                        search={item.search}
                        icon={item.icon}
                        text={item.text}
                        hidden={item.hidden}
                        disabled={item.disabled}
                        onClick={item.onClick}
                        displayExpr={item.displayExpr}
                    />
                </NItem>
            ))}
            {search &&
                <NItem location='before' locateInMenu='auto' cssClass={'fw-dash-toolbar-search-item'}>
                    <ToolbarSearchBtn
                        search={search}
                    />
                </NItem>
            }
            {exp &&
                <NItem location='after' locateInMenu='auto' cssClass='fw-dash-toolbar-export-item'>
                    <ToolbarExportBtn
                        element={exp.element}
                        fileName={exp.fileName}
                        disabled={exp.disabled}
                    />
                </NItem>
            }
        </NToolbar>
    )
});


export {
    DashToolbar
}