// UserCredMana - 사용자 관리 - 신용카드 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { UserCredInsert } from './CardMana-insert';
import { UserCredUpdate } from './CardMana-update';
import { fwUtil } from '../../../../script/util';

const aserStateCell = (cellInfo) => {
    const value = cellInfo.value;
    const text = (!value || value === 0) ? '서브카드' : '메인카드'
    const color = (!value || value === 0) ? '#4075ba' : '#ca5283'

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>
                {text}
            </span>
        </div>
    );
};

export default function UserCredMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'credit/card/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'cred_numb';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건    
    const [userName, setUserName] = useState("");
    const [credName, setCredName] = useState("");
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    const credNameChange = useCallback((e) => { setCredName(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { userName: userName, credName: credName };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [userName, credName, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '소유자명', value: userName, valueChange: userNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '카드사명', value: credName, valueChange: credNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [userName, userNameChange, credName, credNameChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'결제카드 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "cred_name", caption: "카드사명" })}
                    {StatCol({ dataField: "cred_yesn", caption: "카드구분", customCell: aserStateCell })}
                    {CommCol({ dataField: "owne_idno", caption: "소유자ID" })}
                    {CommCol({ dataField: "owne_name", caption: "소유자명" })}
                    {CommCol({ dataField: "mobile_no", caption: "소유자연락처" })}
                    {CommCol({ dataField: "damd_teln", caption: "담당자연락처" })}
                    {CommCol({ dataField: "addr_knam", caption: "주소" })}
                    {CommCol({ dataField: "user_name", caption: "작성자" })}
                    {DateCol({ dataField: "user_date", caption: "작성일" })}
                    {CommCol({ dataField: "modi_name", caption: "수정자" })}
                    {DateCol({ dataField: "modi_date", caption: "수정일" })}
                </PagingGrid>
                <UserCredInsert
                    refresh={handleSearch}
                    insertApi={infoInsertApi}
                    visible={infoInsertPopupVisible}
                    hiding={infoInsertPopupHide}
                />
                <UserCredUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />
            </div>
        </>
    );
};