import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    main_code: '', 
    main_name: '', 
    subs_yesn: true, 
    subs_code: '', 
    subs_name: '', 
    subs_memo: '',
};

export function CodeSubsManaInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);  

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
        const selectedRowData = fwUtil.grid.get.sData(mainGrid);
        if (!selectedRowData) {
            hiding();
        } else {
            const prevData = {
                ...defaultData,
                main_code: selectedRowData.main_code || '',
                main_name: selectedRowData.main_name || '',
            };
            dataUpdate(prevData);
        }
    }, [mainGrid, hiding, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainCode: data.main_code || '', 
            mainName: data.main_name || '', 
            subsYesn: data.subs_yesn || false, 
            subsCode: data.subs_code || '', 
            subsName: data.subs_name || '', 
            subsMemo: data.subs_memo || '',
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupForm
            title={'상세코드 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 340 : 600 }  
        >
            <GroupItem>
                {FormText({ value: data.main_code, onValueChange: updateField('main_code'), label: '관리코드', disabled: true, required: true })} 
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '관리코드명', disabled: true, required: true })} 
                {FormText({ value: data.subs_code, onValueChange: updateField('subs_code'), label: '상세코드', maxLength: 10, required: true, placeholder: "최대 10자리" })}
                {FormText({ value: data.subs_name, onValueChange: updateField('subs_name'), label: '상세코드명', maxLength: 10, required: true, placeholder: "최대 10자리" })}
                {FormBool({ value: data.subs_yesn, onValueChange: updateField('subs_yesn'), label: '사용여부' })}  
                {FormMemo({ value: data.subs_memo, onValueChange: updateField('subs_memo'), label: '비고' })}
            </GroupItem>
        </PopupForm>
        </>
    );
}