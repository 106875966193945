import axios from 'axios';
import * as apisUtil from './utility/util-apis';
import * as convUtil from './utility/util-conv';
import * as dataUtil from './utility/util-data';
import * as dateUtil from './utility/util-date';
import * as gridUtil from './utility/util-grid';
import * as alerUtil from './utility/util-aler';

function axiosInstanceCreate(configs) {
    const axiosInstance = axios.create({ ...configs, withCredentials: true }); // 빌드할 때
    //const axiosInstance = axios.create({ ...configs, baseURL: 'https://localhost', withCredentials: true }); // 혼자할 때
    //const axiosInstance = axios.create({ baseURL: 'http://192.168.0.05:8888', withCredentials: true }); 
    //const axiosInstance = axios.create({ baseURL: 'http://focuswin.iptime.org:8888', withCredentials: true });
    axiosInstance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const apis = {
    check: apisUtil.checkData,
    create: apisUtil.creatApi,
    search: apisUtil.searchData,
    submit: apisUtil.submitData,
    delete: apisUtil.deleteData,
};

const conv = {
    to: convUtil.to,
    tern: convUtil.ternFunc,
    class: convUtil.makeClass,
    check: convUtil.check,
    split: convUtil.getSplitCode,
}

const data = {
    report: dataUtil.reportData,
    export: dataUtil.exportData,
    valdit: dataUtil.valditData,
    alerts: dataUtil.alertsData,
    screen: dataUtil.screenData,
}

const date = {
    getsText: dateUtil.getsText,
    getsList: dateUtil.getsList,
    convTime: dateUtil.convTime,
}

const grid = {
    sel: gridUtil.sel,
    get: gridUtil.get,
};

const aler = {
    toast: alerUtil.toast,
}

const height = {
    window: window.innerHeight > 850 ? window.innerHeight : 850,
    header: 48,
    footer: 70,
    grid: {
        header: 53,
        footer: 56,
        row: {
            head: 42.5,
            body: 37,
        }
    }
}

export const fwUtil = {
    axiosInstanceCreate: axiosInstanceCreate,
    height: height,
    apis: apis,
    conv: conv,
    data: data,
    date: date,
    grid: grid,
    aler: aler,
}