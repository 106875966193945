import { Chart } from "devextreme-react";
import { CommonAxisSettings, Legend, Tooltip } from "devextreme-react/cjs/chart";
import { fwUtil } from "../../util";
import './charts.scss';

const CommChart = (props) => {
    const {
        title, role, additonTitle,  // title
        width, height,              // width, height
        charRef,                    // reference
        chartsDataSource,           // dataSource
        palette, argsMargin,        // style
        legend, tooltip,            // etc
        children,                   // children
    } = props;

    const mainTitle = `${title}${role? " " + role : ""}${additonTitle? " " + additonTitle : ""}`;
    const chartHeight = title ?  height - 32 :  height;
    const axisMarginAble = fwUtil.conv.tern(true, argsMargin);

    return (
        <div className="fw-chart-content">
            { title &&
            <div className="fw-chart-header">
                <span>{mainTitle}</span>
            </div>                
            }
            <div className="fw-chart-body">
                <Chart
                    width={width}
                    height={chartHeight}
                    ref={charRef}
                    dataSource={chartsDataSource}
                    palette={palette}
                >
                    {children}
                    <CommonAxisSettings
                        valueMarginsEnabled={axisMarginAble}
                    />
                    { legend ?
                        (
                        <Legend
                            verticalAlignment={legend.vertical}
                            horizontalAlignment={legend.horizontal}
                        />                    
                        ) :
                        (
                        <Legend
                            visible={false}
                        />                    
                        )               
                    }
                    { tooltip && 
                        <Tooltip
                            enabled
                            location={tooltip.location}
                            customizeTooltip={tooltip.template}
                        />
                    }
                </Chart>            
            </div>            
        </div>
    );
}

export {
    CommChart,
}