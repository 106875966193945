import React, { useState, createContext, useContext, useEffect } from 'react';
import { generateDynamicRoutes } from '../app-routes';

const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props) {
  const [routeCheck, setRouteCheck] = useState(false);
  const [routeLoading, setRouteLoading] = useState(true);
  const [navigationData, setNavigationData] = useState({ currentPath: '' });
  const [cmsRouteList, setCmsRouteList] = useState([]);

  async function getRouteList() {
    try {
      const { cmsRoutes } = await generateDynamicRoutes();
      setCmsRouteList(cmsRoutes);
      setRouteCheck(false);
      setRouteLoading(false);
    } catch (error) {
      setRouteCheck(true);
    } 
  }

  useEffect(() => {
    getRouteList();
  }, []);
  
  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData, routeCheck, routeLoading, cmsRouteList, getRouteList }}
      {...props}
    />
  );
}

function withNavigationWatcher(Component, path) {
  const WrappedComponent = function (props) {
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      setNavigationData && setNavigationData({ currentPath: path });
    }, [setNavigationData]);

    return <Component {...props} />;
  }
  return <WrappedComponent />;
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher
}
