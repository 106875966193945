import ChrgBusiMana from "./busi/InfoMana-page";
import ChrgStatMana from "./stat/InfoMana-page";
import ChrgInfoMana from "./info/infoMana-page";
import ChrgPricMana from "./pric/InfoMana-page";

export const chrg = ({ acce, code }) => {
    const cateCom = 'com';
    const cateEle = 'ele';

    const comp = {
        busiMana: <ChrgBusiMana acce={acce} cate={cateCom} code={`${code}1`} mainTitle={'사업자'} mainRole={'관리'} />,
        statMana: <ChrgStatMana acce={acce} cate={cateEle} code={`${code}2`} mainTitle={'충전소'} mainRole={'관리'} />,
        infoMana: <ChrgInfoMana acce={acce} cate={cateEle} code={`${code}3`} mainTitle={'충전기'} mainRole={'관리'} />,
        pricMana: <ChrgPricMana acce={acce} cate={cateEle} code={`${code}4`} mainTitle={'충전단가'} mainRole={'관리'} />,
    }

    return comp;
}