import React from 'react';
import { useAuth } from '../../../../contexts/auth';
import './UserPanel.scss';


export default function UserPanel({ intrComp }) {
  const { user } = useAuth();
  const userInfo = user ? user.userName : '알수없음';

  return (
    <div className={'user-panel'}>
      <div className={'imag-info'}>
        <img className={'comp-imag'} src={intrComp?.logo} alt={`${intrComp?.name} 로고`} />
      </div>
      <div className={'text-info'}>
        <div className={'comp-info'}>{intrComp.name}</div>        
        <div className={'user-info'}>{userInfo}</div>
      </div>
    </div>
  );
}
