import defaultUser from '../utils/default-user';
import routerInfo from '../utils/router-info';
import { fwUtil } from '../focuswin/script/util';

// 로그인
export async function signIn(userIdno, passWord) {
  const loginData = {
    userIdno: userIdno,
    passWord: passWord
  };
  const axiosInstance = fwUtil.axiosInstanceCreate();
  try {
    const response = await axiosInstance.post(`${routerInfo.manaRout}/sys/login/login`, loginData)
    if (response.data.status === true) {
      defaultUser.userIdno = response.data.userIdno;
      defaultUser.userName = response.data.userName;    
      defaultUser.userLeve = response.data.userLeve;
      const menuList = response.data.userMenu;
      return {
        isOk: true, data: defaultUser, userMenu: menuList
      };
    } else {
      return {
        isOk: false,
        message: response.data.message
      };
    }
  } catch (error) {
    console.error("api-signIn-error");
    return {
      isOk: false,
      message: "로그인 서버 연결에 문제가 발생했습니다."
    };
  }
};

export async function signOut() {
  const axiosInstance = fwUtil.axiosInstanceCreate();
  try {
    const response = await axiosInstance.post(`${routerInfo.manaRout}/sys/login/logout`)
    if (response.status === 200) {
      return {
        isOk: true
      };
    } else {
      return {
        isOk: false
      };
    }
  } catch (error) {
    console.error("api-signOut-error");
    return {
      isOk: false
    };
  }
};

// 사용자 정보 가져오기
export async function getUser() {
  const axiosInstance = fwUtil.axiosInstanceCreate();
  try {
    const response = await axiosInstance.post(`${routerInfo.manaRout}/sys/login/check`)
    if (response.data.status && response.data.userIdno) {
      defaultUser.userIdno = response.data.userIdno;
      defaultUser.userName = response.data.userName;    
      defaultUser.userLeve = response.data.userLeve;   
      const menuList = response.data.userMenu;
      return {
        isOk: true, data: defaultUser, userMenu: menuList
      };
    } else {
      return {
        isOk: false
      };
    }
  } catch (error) {
    console.error("api-getUser-error");
    return {
      isOk: false
    };
  }
};