import React from 'react';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, NumbCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const MoniDashDaysGrid = React.memo((props) => {
    const {
        gridData, gridRef, daysGridHeight,
        mainTitle, mainRole, additonTitle
    } = props;

    // 그리드
    const mainKey = 'DayType';

    return (
        <ScrollGrid
            title={mainTitle}
            role={mainRole}
            additonTitle={additonTitle}
            height={daysGridHeight}
            mainKey={mainKey}
            gridRef={gridRef}
            gridDataSource={gridData}
            loadPanel={false}
            toolbar={{ titleOnly: true }}
            selection={'none'}
        >
            {CommCol({ dataField: "DayType", caption: "구분" })}
            {DeciCol({ dataField: "ChargerKW", caption: "충전량", suffix: "kWh" })}
            {TimeCol({ dataField: "ChargerTime", caption: "사용시간" })}
            {NumbCol({ dataField: "ChargerCount", caption: "충전 횟수" })}
            {DeciCol({ dataField: "ChargerPayment", caption: "충전요금", suffix: "원" })}
        </ScrollGrid>
    );
});

export default MoniDashDaysGrid;
