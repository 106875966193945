import { fwUtil } from "../../focuswin/script/util";
import routerInfo from "../../utils/router-info";

const getCommCodeList = async (type) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const sendData = { mainCode: '100' };
        const response = await axiosInstance.post(`${routerInfo.manaRout}/com/code/subs/search`, sendData);
        const data = response.data.dataSet;
        let filteredData = ['선택하세요'];
        if (data && data.length > 0) {
            const subsNames = data.map(item => `${item.subs_code} : ${item.subs_name}`);
            if (type === 'search') {
                filteredData = [...filteredData, ...subsNames];
            } else {
                filteredData = subsNames;
            }
        }
        return filteredData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export {
    getCommCodeList,
}