import { fwUtil } from "../../focuswin/script/util";
import routerInfo from "../../utils/router-info";

const getStatBusiList = async () => {
    try {
        const api = `${routerInfo.manaRout}/set/ele/station/regist/clientele/list`;
        const data = await fwUtil.apis.search(api);
    
        return data;
    }
    catch (error) {
        console.log('getStatBusiList.error === ', error);
        return [];
    }
};

const getStatUserList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/ele/station/regist/userInfo/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getStatUserList.error === ', error);
        return [];
    }
};

const getStatTyp1List = async () => {
    try {
        const api = `${routerInfo.manaRout}/set/ele/station/regist/statyp1/list`;
        const data = await fwUtil.apis.search(api);
    
        return data;
    }
    catch (error) {
        console.log('getStatTyp1List.error === ', error);
        return [];
    }
};

const getStatTyp2List = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/ele/station/regist/statyp2/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getStatTyp2List.error === ', error);
        return [];
    }
};

export {
    getStatBusiList,
    getStatUserList,
    getStatTyp1List,
    getStatTyp2List,
}

const getCharPageStat = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post(`${routerInfo.manaRout}/ele/set/charger/station/list`);
        const data = response.data.dataSet || [];

        return [{ station_id: "all", station_name: "전체" }, ...data.map(item => ({
            station_id: item.station_id,
            station_name: item.station_name
        }))];

    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [{ station_id: "all", station_name: "전체" }];
    }
};

const getChrgBusiList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/ele/charger/regist/clientele/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getChrgBusiList.error === ', error);
        return [];
    }
};

const getChrgStatList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/ele/set/charger/station/list`;
        const data = await fwUtil.apis.search(api, sendData);

        return data;
    }
    catch (error) {
        console.log('getChrgStatList.error === ', error);
        return [];
    }
};

export {
    getCharPageStat,
    getChrgBusiList,
    getChrgStatList,
}

const getPricChrgList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/ele/price/regist/charger/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getPricChrgList.error === ', error);
        return [];
    }
};

export {
    getPricChrgList
}