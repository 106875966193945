// AserChrgStat - 정산/지표 분석 - 충전기 그래프 조회
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, NumbCol, TimsCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { fwUtil } from '../../../../script/util';
import { convertChartData } from './InfoDash-util';
import ChrgInfoChart from './InfoDash-chrgInfo';
//import OperInfoChart from './InfoDash-operInfo';

const dateList = ["년", "월", "일"];
const typeGubuList = ["충전소별", "충전기별"];
function convDateGubu(dateString) {
    if (dateString === "년") {
        return 0;
    } else if (dateString === "월") {
        return 1;
    } else {
        return 2;
    }
}

function formatConnectorId(data) {
    if (!data || isNaN(data.connector_id)) {
        return '--';
    }
    
    const connectorId = data.connector_id;
    return connectorId.toString().padStart(2, '0');
}

export default function AnalChrgStat(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const mainMiddleApi = 'charge/header/status';
    const subsMiddleApi = 'charge/detail/status';
    const mainSearchApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'search');
    const subsSearchApi = fwUtil.apis.create(acce, cate, subsMiddleApi, 'search');
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const { isLarge } = useScreenSize();
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    const actsEffeCharTitle = "충전 상태 및 효율";
    //const costUtilCharTitle = "비용 및 가동율";    
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 데이터
    const gridKey = ["station_id", "chargePointId", "connectorId"];
    const [gridData, setGridData] = useState([]);
    const [charData, setCharData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    const charDataUpdate = useCallback((e) => setCharData(e), []);
    const gridRef = useRef(null);
    const [gridHeight, setGridHeight] = useState(0);
    const actsEffeCharRef = useRef(null);
    //const costUtilCharRef = useRef(null);
    // 높이
    const charHeight = 400;
    // 검색 조건
    const [stationName, setStationName] = useState("");
    const [charDate, setCharDate] = useState(fwUtil.date.getsText.date());
    const [statGubu, setStatGubu] = useState("충전소별");
    const [dateGubu, setDateGubu] = useState("일");
    const stationNameChange = useCallback((e) => { setStationName(e) }, []);
    const charDateChange = useCallback((e) => { setCharDate(e) }, []);
    const statGubuChange = useCallback((e) => { setStatGubu(e) }, []);
    const dateGubuChange = useCallback((e) => {
        if (e === "년") {
            charDateChange(fwUtil.date.getsText.year());
        } else if (e === "월") {
            charDateChange(fwUtil.date.getsText.month());
        } else if (e === "일") {
            charDateChange(fwUtil.date.getsText.date());
        }
        setDateGubu(e);
    }, [charDateChange]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const gridHandleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { stationName: stationName, dateGubu: convDateGubu(dateGubu), charDate: charDate, statGubu: statGubu === "충전소별" ? 0 : 1 };
        await fwUtil.apis.search(mainSearchApi, sendData, gridDataUpdate);
        charDataUpdate([]);
        setPageLoading(false);
    }, [mainSearchApi, gridDataUpdate, stationName, dateGubu, charDate, statGubu, charDataUpdate]);

    const charHandleSearch = useCallback(async () => {
        setPageLoading(true);
        let keyData;
        keyData = fwUtil.grid.get.sKey(gridRef);

        if (keyData) {
            const sendData = { stationId: keyData.station_id, chargePointId: keyData.chargePointId, connectorId: keyData.connectorId, charDate: charDate, dateGubu: convDateGubu(dateGubu), statGubu: statGubu === "충전소별" ? 0 : 1 };
            const resData = await fwUtil.apis.search(subsSearchApi, sendData);
            const convData = await convertChartData(resData, charDate, dateGubu);
            charDataUpdate(convData);
        } else {
            charDataUpdate([]);
        }
        setPageLoading(false);
    }, [gridRef, dateGubu, charDate, statGubu, charDataUpdate, subsSearchApi]);

    useEffect(() => {
        gridHandleSearch();
    }, [gridHandleSearch, charDate, statGubu]);

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const contentHeight = viewportHeight - 60 - 57 - 74 - charHeight - 40;
        setGridHeight(contentHeight);
    }, []);

    const gridClick = useCallback(({ key }) => {
        if (key) {
            charHandleSearch();
        }
    }, [charHandleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '사업장명', value: stationName, valueChange: stationNameChange, search: gridHandleSearch },
        { type: 'selc', locate: 'before', caption: '날짜구분', value: dateGubu, valueChange: dateGubuChange, search: gridHandleSearch, items: dateList, width: 70 },
        { type: 'date', locate: 'before', caption: '조회일자', value: charDate, valueChange: charDateChange, search: gridHandleSearch, hidden: dateGubu !== '일' },
        { type: 'mont', locate: 'before', caption: '조회일자', value: charDate, valueChange: charDateChange, search: gridHandleSearch, hidden: dateGubu !== '월' },
        { type: 'year', locate: 'before', caption: '조회일자', value: charDate, valueChange: charDateChange, search: gridHandleSearch, hidden: dateGubu !== '년' },
        { type: 'selc', locate: 'before', caption: '조회구분', value: statGubu, valueChange: statGubuChange, search: gridHandleSearch, items: typeGubuList },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: gridHandleSearch }
    ], [stationName, stationNameChange, dateGubu, dateGubuChange, charDate, charDateChange, statGubu, statGubuChange, gridHandleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-anal'} title={title} />
            <LoadPanel visible={pageLoading} shadingColor='rgba(0, 0, 0, .4)'/>
            <div id={'fw-page-content'} className={`fw-column-flex-box`}>
                <ScrollGrid
                    title={"충전 현황"}
                    mainKey={gridKey}
                    height={gridHeight}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    onRowClick={gridClick}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                    loadHide
                >
                    {CommCol({ dataField: "station_name", caption: "사업장명", width: 120 })}
                    {CommCol({ dataField: "chargePointId", caption: "충전기ID", visible: statGubu === "충전기별", width: 180 })}
                    {CommCol({ dataField: "connectorId", caption: "커넥터ID", visible: statGubu === "충전기별", width: 100, displayExpr: (e) => formatConnectorId(e) })}.
                    {NumbCol({ dataField: "char_qtys", caption: "사용횟수", suffix: "회", width: 120 })}
                    {DeciCol({ dataField: "char_powe", caption: "충전량", suffix: "kWh", width: 200 })}
                    {DeciCol({ dataField: "char_pric", caption: "충전금액", suffix: "원", width: 200 })}
                    {TimsCol({ dataField: "tran_dura", caption: "충전시간" })}
                </ScrollGrid>
                <div className={`fw-${isLarge ? 'row' : 'column'}-flex-box fw-dual-chart-content`}>
                    <ChrgInfoChart charData={charData} charRef={actsEffeCharRef} mainTitle={actsEffeCharTitle} height={charHeight} />
                    {/*<OperInfoChart charData={charData} charRef={costUtilCharRef} mainTitle={costUtilCharTitle} height={charHeight} />*/}
                </div>
            </div>
        </>
    );
};