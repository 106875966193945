import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    owne_idno: '',
    rfid_numb: '0000000000000000',
    rfid_name: '',
    rfid_yesn: true,
    rfid_cars: '',
};

export function UserRfidUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);

            if (seleData) {
                const targData = {
                    owne_idno: seleData.owne_idno,
                    rfid_numb: seleData.rfid_numb,
                    rfid_name: seleData.rfid_name,
                    rfid_yesn: seleData.rfid_yesn,
                    rfid_cars: seleData.rfid_cars,
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            owneIdno: data.owne_idno || '',
            rfidNumb: data.rfid_numb || '0000000000000000',
            rfidName: data.rfid_name || '',
            rfidYesn: data.rfid_yesn ? 1 : 0,
            rfidCars: data.rfid_cars || '',
        };

        await fwUtil.apis.submit(updateApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'결제카드 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 600}
            >
                <GroupItem>
                    {FormText({ value: data.owne_idno, onValueChange: updateField('owne_idno'), label: '소유자ID', required: true })}
                    {FormText({ value: data.rfid_name, onValueChange: updateField('rfid_name'), label: '카드명', required: true })}
                    {FormText({ value: data.rfid_numb, onValueChange: updateField('rfid_numb'), label: '카드번호', required: true, disabled: true })}
                    {FormText({ value: data.rfid_cars, onValueChange: updateField('rfid_cars'), label: '사용차량번호', required: true })}
                    {FormBool({ value: data.rfid_yesn, onValueChange: updateField('rfid_yesn'), label: '사용여부' })}
                </GroupItem>
            </PopupForm>
        </>
    );
}