import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react';

const PopupToolbar = (props) => {
    const {
        title, type, onSubmit, onClose
    } = props;

    return (
        <div className='fw-popup-toolbar'>
            <Toolbar>
                { title &&
                <ToolbarItem location='before'>
                    <span className='fw-popup-toolbar-title'>{title}</span>
                </ToolbarItem>    
                }            
                <ToolbarItem location='after'>
                    <Button text={`${type}`} stylingMode='contained' type='default' onClick={onSubmit} />
                </ToolbarItem>
                <ToolbarItem location='after'>
                    <Button text='닫기' stylingMode='outlined' type='default' onClick={onClose} />
                </ToolbarItem>
            </Toolbar>
        </div>
    )
}

export {
    PopupToolbar
}