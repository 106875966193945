// MoniMapsStat - 모니터링 - 설치위치
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { ContentHeader } from '../../../../script/components/header/Header';
import { CommMaps } from '../../../../script/components/map/map';
import { MoniMapsPanel } from './MoniMaps-panel';
import { fwUtil } from '../../../../script/util';
import { LoadOverlay } from '../../../../script/components/load-overlay/load-overlay';
import { PopupWarn } from '../../../../script/components/popup/popup';
import mapUtil from '../../../../script/components/map/map-util';

const { kakao } = window;

export default function MoniMapsStat(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const stationMiddleApi = 'mapview/status/station';
    const chargerMiddleApi = 'mapview/status/charger';
    const stationSearchApi = fwUtil.apis.create(acce, cate, stationMiddleApi, 'search');
    const chargerSearchApi = fwUtil.apis.create(acce, cate, chargerMiddleApi, 'search');
    // 시스템
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [kakaoLoading, setKakaoLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    // 지도
    const searchExpr = ['station_name', 'station_address', 'char_name'];
    const [stationData, setStationData] = useState([]);
    const stationDataUpdate = useCallback((e) => setStationData(e), []);
    const [chargerData, setChargerData] = useState([]);
    const chargerDataUpdate = useCallback((e) => setChargerData(e), []);
    const [mapsRefs, setMapsRefs] = useState(null);
    const mapsRefsSetting = useCallback((e) => setMapsRefs(e), []);
    const [geoCoder, setGeoCoder] = useState(null);
    const geoCoderSetting = useCallback((e) => setGeoCoder(e), []);
    const mapsViewType = ['ROADMAP', 'SKYVIEW']; // HYBRID, ROADVIEW, OVERLAY, TRAFFIC, TERRAIN, BICYCLE, BICYCLE_HYBRID, USE_DISTRICT
    const viewControler = {
        able: true,
        position: 'TOPRIGHT',
    };
    const zoomControler = {
        able: true,
        position: 'RIGHT',
    };
    // 카카오 API 확인 팝업
    const [kakaoCheckPopupVisible, setKakaoCheckPopupVisible] = useState(false);
    const kakaoCheckPopupHide = useCallback(() => setKakaoCheckPopupVisible(false), []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, null, code);
    }, [authCheck, setLoading, code]);

    // 카카오 지도 API 키 확인
    useEffect(() => {
        if (!kakao) {
            setKakaoCheckPopupVisible(true);
        } else {
            setKakaoLoading(false);
        }
    }, []);

    // 조회
    const staionHandleSearch = useCallback(async () => {
        if (!loading) {
            const sendData = { stationName: "" };
            const resData = await fwUtil.apis.search(stationSearchApi, sendData);
            stationDataUpdate(resData);
            await mapUtil.set.marker.station(mapsRefs, geoCoder, resData);
            setPageLoading(false);
        }
    }, [loading, stationSearchApi, stationDataUpdate, mapsRefs, geoCoder]);

    const chargerHandleSearch = useCallback(async (id, active) => {
        const sendData = { stationId: id };
        const resData = await fwUtil.apis.search(chargerSearchApi, sendData);
        chargerDataUpdate(resData);
        setPageLoading(false);
        active(true);
    }, [chargerSearchApi, chargerDataUpdate]);

    useEffect(() => {
        staionHandleSearch();
    }, [staionHandleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    if (kakaoLoading) { return <PopupWarn closeHide visible={kakaoCheckPopupVisible} hiding={kakaoCheckPopupHide} confirm={() => navigate('/moni/dash/stat')} confirmText={"확인"} message={"카카오 맵스 API키 확인이 불가합니다. 메인페이지로 돌아갑니다."} /> }

    return (
        <>
            <LoadOverlay visible={pageLoading} />
            <ContentHeader icon={'fw-moni'} title={title} />
            <div id={'fw-page-content'}>
                <CommMaps
                    title={title}
                    setMapInstance={mapsRefsSetting}
                    setGeoInstance={geoCoderSetting}
                    view={{ controler: viewControler, type: mapsViewType }}
                    zoom={{ controler: zoomControler, max: 12 }}
                >
                    <MoniMapsPanel
                        stationData={stationData}
                        chargerData={chargerData}
                        chargerSearch={chargerHandleSearch}
                        searchExpr={searchExpr}
                        map={mapsRefs}
                        setLoading={setPageLoading}
                    />
                </CommMaps>
            </div>
        </>
    );
};