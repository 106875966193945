import React, { useCallback } from "react";
import { Button, DateBox, SelectBox, TextBox, Tooltip } from "devextreme-react"
import { fwUtil } from "../../util";

const ToolbarButton = (props) => {
    const {
        icon, text,
        hidden, disabled,
        onClick
    } = props;

    const randomId = fwUtil.grid.get.randomKeys();
    const buttonId = `fw-${randomId}-button-wrapper`;

    return (
        <div id={buttonId} className={'fw-tooltip-button-wrapper'}>
            <Button
                icon={icon}
                text={text}
                type='normal'
                stylingMode='contained'
                visible={!hidden}
                disabled={disabled}
                onClick={onClick}
                elementAttr={{ title: text }}
            />
            {disabled && (
                <Tooltip
                    target={`#${buttonId}`}
                    position={'top'}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                >
                    <span>{text} 권한이 없습니다.</span>
                </Tooltip>
            )}
        </div>
    )
};

const SearchButton = (props) => {
    const { search } = props;

    return ( <ToolbarButton icon="search" text="조회" onClick={search} /> )
}

const ToolbarTextBox = (props) => {
    const {
        caption, width, value, valueChange, search
    } = props;

    return (
        <TextBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            onEnterKey={search}
        />
    )
}

const ToolbarSelcBox = (props) => {
    const {
        caption, items, width, value, valueChange, search, displayExpr
    } = props;

    return (
        <SelectBox
            placeholder={caption}
            items={items}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            onEnterKey={search}
            displayExpr={displayExpr}
        />
    )
}

const ToolbarYearBox = (props) => {
    const {
        caption, width, value, valueChange, search, hidden
    } = props;

    return (
        <DateBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            displayFormat={'yyyy'}
            dateSerializationFormat={"yyyy"}
            type="date"
            calendarOptions={{ maxZoomLevel: 'decade' }}
            onEnterKey={search}
            visible={!hidden}
        />
    )
}

const ToolbarMontBox = (props) => {
    const {
        caption, width, value, valueChange, search, hidden
    } = props;

    return (
        <DateBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            displayFormat={'yyyy-MM'}
            dateSerializationFormat={"yyyy-MM"}
            type="date"
            calendarOptions={{ maxZoomLevel: 'year' }}
            onEnterKey={search}
            visible={!hidden}
        />
    )
}

const ToolbarDateBox = (props) => {
    const {
        caption, width, value, valueChange, search, hidden
    } = props;

    return (
        <DateBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            displayFormat={'yyyy-MM-dd'}
            dateSerializationFormat={"yyyy-MM-dd"}
            type="date"
            calendarOptions={{ maxZoomLevel: 'month' }}
            onEnterKey={search}
            visible={!hidden}
        />
    )
}

const ToolbarSearchBtn = React.memo(function ToolbarSearchBtn({ search }) {
    if (!search) { return; }

    return ( <ToolbarButton icon="search" text="조회" onClick={search} /> )
});

const ToolbarExportBtn = React.memo(function ToolbarSearchBtn({ element, fileName, disabled }) {
    const onExportClick = useCallback(() => {
        const targetElement = element?.current;
        if (!targetElement) {
            fwUtil.aler.toast.erro('다운로드에 실패하였습니다.');
            return;
        }
        fwUtil.data.screen(targetElement, 'l', fileName);
    }, [element, fileName]);
    
    if (!element || !fileName) {
        return;
    }

    return ( <Button icon='export' text='다운로드' onClick={onExportClick} disabled={disabled} /> )
});

const ToolbarItem = ({ type, caption, value, valueChange, items, search, icon, text, hidden, disabled, onClick, displayExpr, width }) => {
    if (type === 'text') {
        return (
            <ToolbarTextBox
                caption={caption}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
            />
        )
    }
    if (type === 'selc') {
        return (
            <ToolbarSelcBox
                caption={caption}
                items={items}
                value={value}
                width={width}
                valueChange={valueChange}
                onEnterKey={search}
                displayExpr={displayExpr}
            />
        )
    }
    if (type === 'btns') {
        return (
            <ToolbarButton
                icon={icon}
                text={text}
                hidden={hidden}
                disabled={disabled}
                onClick={onClick}
            />
        )
    }
    if (type === 'year') {
        return (
            <ToolbarYearBox
                caption={caption}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
                hidden={hidden}
            />
        )
    }
    if (type === 'mont') {
        return (
            <ToolbarMontBox
                caption={caption}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
                hidden={hidden}
            />
        )
    }
    if (type === 'date') {
        return (
            <ToolbarDateBox
                caption={caption}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
                hidden={hidden}
            />
        )
    }        
}

export {
    ToolbarItem,
    ToolbarSearchBtn,
    ToolbarExportBtn,
}

export {
    ToolbarButton,
    SearchButton,
    ToolbarTextBox,
    ToolbarSelcBox,
    ToolbarYearBox,
};