import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { getChrgBusiList } from '../../../../../api/cms/set-chrg';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormRegx, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const defaultData = {
    clie_code: '',
    clie_name: '',
    station_name: '',
    station_id: '',
    stat_envi: '',
    charger_name: '',
    id: '',
    char_enid: '',
    char_prot: 'OCPP1.6',
    gps_xpos: '',
    gps_ypos: '',
    model: '7kW 싱글',
    char_sped: '완속',
    charger_type: '완속형',
    char_con1: '없음',
    char_con2: '없음',
    char_publ: '완전공용',
    char_enab: false,
    char_init: fwUtil.date.getsText.date(),
    char_date: null,
    char_addr: '',
    char_envs: false,
    char_mode: '',
    char_teln: '',
};

// 선택 항목
const charProtList = ['OCPP1.6', 'EvCMS'];
const charSpedList = ['완속', '급속'];
const charTypeList = ['완속형', '급속형'];
const charConnList = ['없음', 'AC완속', 'DC콤보'];
const charPublList = ['완전공용', '부분공용', '비공용'];

export function ChrgInfoManaUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 팝업 그리드
    const busiGridRef = useRef(null);
    const [busiData, setBusiData] = useState([]);
    const busiGridUpdate = useCallback((e) => setBusiData(e), []);
    const busiKeyExpr = 'clie_code';
    // 그리드 팝업
    const [busiPopupVisible, setBusiPopupVisible] = useState(false);
    const busiPopupHiding = useCallback(() => setBusiPopupVisible(false), []);
    // 그리드 조회
    const busiHandleSearch = useCallback(async () => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await getChrgBusiList(sendData);
        busiGridUpdate(res);
    }, [busiGridUpdate]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
            console.log(seleData)
            if (seleData) {
                const targData = {
                    clie_code: seleData.vendor || '',
                    clie_name: seleData.clie_name || '',
                    station_name: seleData.station_name || '',
                    station_id: seleData.station_id || '',
                    stat_envi: seleData.stat_envi || '',
                    charger_name: seleData.charger_name || '',
                    id: seleData.id || '',
                    char_enid: seleData.char_enid || '',
                    char_prot: seleData.char_prot || '',
                    gps_xpos: seleData.gps_xpos || '',
                    gps_ypos: seleData.gps_ypos || '',
                    model: seleData.model || '',
                    char_sped: seleData.char_sped || '',
                    charger_type: seleData.charger_type || '',
                    char_con1: seleData.char_con1 || '없음',
                    char_con2: seleData.char_con2 || '없음',
                    char_publ: seleData.char_publ || '',
                    char_enab: seleData.char_enab === 0 ? false : true,
                    char_init: seleData.char_init || fwUtil.date.getsText.date(),
                    char_date: seleData.char_date || null,
                    char_addr: seleData.char_addr || '',
                    char_envs: seleData.char_envs === 0 ? false : true,
                    char_mode: seleData.char_mode || '',
                    char_teln: seleData.char_teln || '',                    
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            console.log(error)
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            vendor: data.clie_code || '',
            clieName: data.clie_name || '',
            stationName: data.station_name || '',
            stationId: data.station_id || '',
            statEnvi: data.stat_envi || '',
            chargerName: data.charger_name || '',
            id: data.id || '',
            charEnid: data.char_enid || '',
            charProt: data.char_prot || 'OCPP1.6',
            gpsXpos: data.gps_xpos || '',
            gpsYpos: data.gps_ypos || '',
            model: data.model || '7kW 싱글',
            charSped: data.char_sped || '완속',
            chargerType: data.charger_type === '완속형' ? 0 : 1,
            charCon1: data.char_con1 || '없음',
            charCon2: data.char_con2 || '없음',
            charPubl: data.char_publ || '완전공용',
            charEnab: data.char_enab === 0 ? false : true,
            charInit: data.char_init ? fwUtil.date.convTime.fmDate(data.char_init) : fwUtil.date.getsText.date(),
            charDate: data.char_date ? fwUtil.date.convTime.fmDate(data.char_init) : null,
            charAddr: data.char_addr || '',
            charEnvs: data.char_envs === 0 ? false : true,
            charMode: data.char_mode || '',
            charTeln: data.char_teln || '',         
        };

        await fwUtil.apis.check(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    const busiPopupOnShowing = useCallback(() => {
        busiHandleSearch();
    }, [busiHandleSearch]);

    const busiOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'clie_code': e.data.clie_code, 'clie_name': e.data.clie_name });
        busiPopupHiding();
    }, [data, dataUpdate, busiPopupHiding]);

    const busiGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'사업자'}
                role={'목록'}
                gridDataSource={busiData}
                gridRef={busiGridRef}
                mainKey={busiKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={busiOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명" })}
                {CommCol({ dataField: "addr_knam", caption: "주소" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}
            </ScrollGrid>
        )
    }, [busiData, busiGridRef, busiKeyExpr, busiOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'사업자명 조회'}
                visible={busiPopupVisible}
                shown={busiPopupOnShowing}
                hiding={busiPopupHiding}
            >
                {busiGrid}
            </PopupGrid>
            <PopupForm
                title={'충전기 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : isMedium ? 900 : 1240}
            >
                <GroupItem colCount={isXSmall || isSmall || isMedium ? 1 : 2}>
                    {FormGrid({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '사업자명', required: true, onClick: () => setBusiPopupVisible(true) })}
                    {FormGrid({ value: data.station_name, onValueChange: updateField('station_name'), label: '충전소명', required: true, disabled: true })}
                    {FormText({ value: data.station_id, onValueChange: updateField('station_id'), label: '충전소ID', required: true, disabled: true })}
                    {FormText({ value: data.stat_envi, onValueChange: updateField('stat_envi'), label: '환경부용 충전소ID', disabled: true })}
                    {/*FormRegx({ value: data.charger_name, onValueChange: updateField('charger_name'), label: '충전기명', required: true, rule: /^[a-zA-Z가-힣0-9\s]{1,50}$/, placeholder: '영문, 한글, 숫자 50자' })*/}
                    {FormText({ value: data.id, onValueChange: updateField('id'), label: '충전기ID', required: true, disabled: true })}
                    {FormRegx({ value: data.char_enid, onValueChange: updateField('char_enid'), label: '환경부용 충전기ID', rule: /^[0-9]{1,2}$/, placeholder: '숫자 2자' })}
                    {FormText({ value: data.gps_xpos, onValueChange: updateField('gps_xpos'), label: '위도', disabled: true })}
                    {FormText({ value: data.gps_ypos, onValueChange: updateField('gps_ypos'), label: '경도', disabled: true })}
                    {FormSelc({ value: data.model, onValueChange: updateField('model'), label: '충전기 모델', items: ['7kW 싱글', '14kW 싱글', '14kW 듀얼', '50kW 듀얼', '100kW 싱글', '100kW 듀얼'], required: true })}
                    {FormSelc({ value: data.char_sped, onValueChange: updateField('char_sped'), label: '충전기 속도', items: charSpedList, required: true })}
                    {FormSelc({ value: data.charger_type, onValueChange: updateField('charger_type'), label: '충전기 유형', items: charTypeList, required: true })}
                    {FormSelc({ value: data.char_publ, onValueChange: updateField('char_publ'), label: '공용구분', items: charPublList, required: true })}
                    {FormSelc({ value: data.char_con1, onValueChange: updateField('char_con1'), label: '커넥터 타입1', items: charConnList, required: true })}
                    {FormSelc({ value: data.char_con2, onValueChange: updateField('char_con2'), label: '커넥터 타입2', items: charConnList, required: true })}
                    {FormDate({ value: data.char_init, onValueChange: updateField('char_init'), label: '설치일' })}
                    {FormDate({ value: data.char_date, onValueChange: updateField('char_date'), label: '운영시작일' })}
                    {FormBool({ value: data.char_enab, onValueChange: updateField('char_enab'), label: '운영여부' })}
                    {FormBool({ value: data.char_envs, onValueChange: updateField('char_envs'), label: '환경부 로밍여부' })}
                    {FormSelc({ value: data.char_prot, onValueChange: updateField('char_prot'), label: '프로토콜', items: charProtList, required: true })}
                    {FormRegx({ value: data.char_addr, onValueChange: updateField('char_addr'), label: 'Anydesk ID', rule: /^[a-zA-Z0-9]{0,20}$/, placeholder: '영문, 숫자 20자' })}
                    {FormRegx({ value: data.char_mode, onValueChange: updateField('char_mode'), label: '시리얼 번호', rule: /^[a-zA-Z0-9]{0,20}$/, placeholder: '영문, 숫자 20자' })}
                    {FormRegx({ value: data.char_teln, onValueChange: updateField('char_teln'), label: '전화번호', rule: /^[a-zA-Z0-9]{0,20}$/, placeholder: '영문, 숫자 20자' })}
                </GroupItem>
            </PopupForm>
        </>
    );
};