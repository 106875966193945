export async function numbBeforeChange(e, integerLength, decimalLength) {
    if (!integerLength || (!decimalLength && decimalLength !== 0)) {
        return e;
    }
    let newValue = e;

    if (isNaN(newValue)) {
        newValue = 0;
    }
    
    let newValueStr = String(newValue);
    
    const decimalIndex = newValueStr.indexOf('.');
    if (decimalIndex !== -1) {
        const decimalPartStr = newValueStr.slice(-(decimalLength + 1));
        const integerPartStr = newValueStr.substring(0, decimalIndex);
        if (integerPartStr.length > integerLength) {
            newValueStr = integerPartStr.substring(0, integerLength);
        }
        newValueStr = newValueStr + decimalPartStr;
    } else {
        if (newValueStr.length > integerLength) {
            
            newValueStr = newValueStr.substring(0, integerLength);
        }
    }
    return newValueStr;
};